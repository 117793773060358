export const templatedLawnCare = [
  {
    id: "lawn-questions",
    steps: [
      {
        id: "fertnweed-entry",
        message: `Have you been doing the lawn yourself or having another company take care of the lawn?`,
        trigger: "get-fertnweed-options"
      },
      {
        id: "confirmation-upkeep",
        message: `That's awesome! I am glad to hear you are taking pride in the yard. Believe it or not, not everybody cares about their yard as you do.`,
        trigger: "current-happiness"
      },
      {
        id: "welcome-to-neighborhood",
        message: `Welcome to the neighborhood! New homeowners love our easy set it and forget it service.`,
        trigger: "current-happiness"
      },
      {
        id: "provider-upkeep-acknowledge",
        message: `That's awesome, I am glad to hear you are taking pride in the yard. Believe it or not, not everybody cares about their yard like you do.`,
        trigger: "problems-with-provider"
      },
      {
        id: "problems-with-provider",
        message:
          "What are you looking for that your current company isn't providing?",
        trigger: "service-provider-missing"
      },
      { id: "thanksman", message: `reached the end`, trigger: "next-service" },
      {
        id: "provider-frusturating",
        message: `You have no idea how much we hear that. Believe me I know exactly how frusturating it can be to find a quality company.`,
        trigger: "current-happiness"
      },
      {
        id: "current-happiness",
        message: "What is your current happiness with the lawn?",
        trigger: "get-current-happiness"
      },
      {
        id: "10-lawn",
        message:
          "Great we will help keep the lawn looking beautiful for you this season.",
        trigger: "lawn-thickness-ask"
      },
      {
        id: "9orless-lawn",
        message: `Great #name#, we will not stop working until we get your lawn to a 5 out of 5!`,
        trigger: "lawn-thickness-ask"
      },
      {
        id: "lawn-thickness-ask",
        message: "What is the current thickness of the lawn?",
        trigger: "get-lawn-thickness"
      },
      {
        id: "lawn-thickness-response",
        message:
          "Wonderful! Your program will be specially blended based on your selection!",
        trigger: "biggest-issue-ask"
      },
      {
        id: "biggest-issue-ask",
        message: "What's the biggest issue you are having with the lawn?",
        trigger: "get-biggest-issue"
      },
      {
        id: "lawn-type-ask",
        message: "What kind of grass type do you have in your lawn?",
        trigger: "get-lawn-type"
      }
    ],
    probing_questions: [
      {
        id: "get-fertnweed-options",
        setVariable: "lawnCareParty",
        options: [
          {
            value: "I do it myself",
            label: "I do it myself",
            iconClass: ["fas", "user-alt"],
            color: "yellow"
          },
          {
            value: "I use another company",
            label: "I use another company",
            iconClass: ["fas", "cog"],
            color: "yellow"
          },
          {
            value: "I just moved in",
            label: "I just moved in",
            iconClass: ["fas", "people-carry"],
            color: "yellow"
          }
        ],
        optionType: "icon-text-option",
        fillOptions: true,
        conditionals: [
          { matches: "I do it myself", trigger: "confirmation-upkeep" },
          {
            matches: "I use another company",
            trigger: "provider-upkeep-acknowledge"
          },
          { matches: "I just moved in", trigger: "welcome-to-neighborhood" }
        ]
      },
      //// question 2
      {
        id: "get-current-happiness",
        component: {
          type: "rating",
          placeholder: 3
        },
        waitAction: true,
        setVariable: "lawnHappiness",
        trigger: "9orless-lawn"
      },

      /// question 3
      {
        id: "service-provider-missing",
        options: [
          {
            value: "Customer Service",
            label: "Customer Service",
            hideText: true
          },
          { value: "Better Quality", label: "Better Quality", hideText: true },
          { value: "Better Pricing", label: "Better Pricing", hideText: true }
        ],
        optionType: "default",
        setVariable: "whyNewProvider",
        trigger: "provider-frusturating"
      },
      /// question 4
      {
        id: "get-lawn-thickness",
        options: [
          {
            value: "Thick",
            label: "Thick",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-thick.jpg"
          },
          {
            value: "Patchy",
            label: "Patchy",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-patchy.jpg"
          },
          {
            value: "Thin",
            label: "Thin",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-thin.jpg"
          }
        ],
        setVariable: "lawnThickness",
        optionType: "image-text-option",
        trigger: "lawn-thickness-response"
      },
      /// question 5
      {
        id: "get-biggest-issue",
        options: [
          {
            value: "Broadleaf weeds",
            label: "Broadleaf weeds",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-broadleaf-weeds.jpg"
          },
          {
            value: "Stubborn Weeds",
            label: "Stubborn Weeds",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-stubborn-weeds.jpg"
          },
          {
            value: "Grubs",
            label: "Grubs",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-grubs.jpg"
          },
          {
            value: "Crabgrass",
            label: "Crabgrass",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-crabgrass.jpg"
          },
          {
            value: "Disease",
            label: "Disease",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-disease.jpg"
          },
          {
            value: "Shade",
            label: "Shade",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-shade.jpg"
          }
        ],
        setVariable: "lawnBiggestProblem",
        optionType: "image-text-option",
        fillOptions: true,
        trigger: "next-service"
      },
      /// question 6
      {
        id: "get-lawn-type",
        options: [
          {
            value: "Bermuda",
            label: "Bermuda",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-bermuda-grass.jpg"
          },
          {
            value: "Zoysia",
            label: "Zoysia",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-zoysia-grass.jpg"
          },
          {
            value: "Tall fescue",
            label: "Tall fescue",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-tall-fescue.jpg"
          },
          {
            value: "St augistine",
            label: "St augistine",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-st-augistine.jpg"
          },
          {
            value: "I don't know",
            label: "I don't know",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/I-dont-know.jpg"
          }
        ],
        setVariable: "lawnType",
        optionType: "image-text-option",
        fillOptions: true,
        trigger: "next-service"
      }
    ]
  }
];
