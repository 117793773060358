export const templatedMosquito = [
  {
    id: "mosquito-questions",
    steps: [
      {
        id: "mosquito-entry",
        message: `Did you spray for the bugs yourself last year or did you hire another company?`,
        trigger: "get-mosquito-options"
      },
      {
        id: "confirmation-upkeep",
        message: `That's awesome! I am glad to hear you are taking pride in the yard. Believe it or not, not everybody cares about their yard as you do.`,
        trigger: "current-happiness"
      },
      {
        id: "welcome-to-neighborhood",
        message: `Welcome to the neighborhood! New homeowners love our easy set it and forget it service.`,
        trigger: "current-happiness"
      },
      {
        id: "provider-upkeep-acknowledge",
        message: `That's awesome, I am glad to hear you are taking pride in the yard. Believe it or not, not everybody cares about their yard like you do.`,
        trigger: "traditional-or-organic"
      },
      {
        id: "traditional-or-organic",
        message: "Are you looking for our traditional or organic program?",
        trigger: "get-traditional-or-organic"
      },
      { id: "thanksman", message: `reached the end`, trigger: "next-service" },
      {
        id: "provider-frusturating",
        message: `You have no idea how much we hear that. Believe me I know exactly how frusturating it can be to find a quality company.`,
        trigger: "current-happiness"
      },
      {
        id: "mosquito-traditional",
        message: "Great Traditional",
        trigger: "bugging-you"
      },
      {
        id: "mosquito-organic",
        message: "Great Organic",
        trigger: "bugging-you"
      },
      {
        id: "bugging-you",
        message: `What is the biggest problem bugging you out there?`,
        trigger: "lawn-thickness-ask"
      },
      {
        id: "lawn-thickness-ask",
        message: "What is the current thickness of the lawn?",
        trigger: "get-lawn-thickness"
      },
      {
        id: "lawn-thickness-response",
        message:
          "Wonderful! Your program will be specially blended based on your selection!",
        trigger: "biggest-issue-ask"
      },
      {
        id: "biggest-issue-ask",
        message: "What's the biggest issue you are having with the lawn?",
        trigger: "get-biggest-issue"
      },
      {
        id: "lawn-type-ask",
        message: "What kind of grass type do you have in your lawn?",
        trigger: "get-lawn-type"
      }
    ],
    probing_questions: [
      {
        id: "get-mosquito-options",
        setVariable: "mosquitoCareParty",
        options: [
          {
            value: "I do it myself",
            label: "I do it myself",
            iconClass: ["fas", "user-alt"],
            color: "yellow"
          },
          {
            value: "I use another company",
            label: "I use another company",
            iconClass: ["fas", "cog"],
            color: "yellow"
          },
          {
            value: "I just moved in",
            label: "I just moved in",
            iconClass: ["fas", "people-carry"],
            color: "yellow"
          }
        ],
        optionType: "icon-text-option",
        fillOptions: true,
        conditionals: [
          { matches: "I do it myself", trigger: "confirmation-upkeep" },
          {
            matches: "I use another company",
            trigger: "provider-upkeep-acknowledge"
          },
          { matches: "I just moved in", trigger: "welcome-to-neighborhood" }
        ]
      },

      {
        id: "get-traditional-or-organic",
        setVariable: "traditionalOrOrganic",
        options: [
          {
            value: "Traditional",
            label: "Traditional",
            iconClass: ["fas", "user-alt"],
            color: "yellow"
          },
          {
            value: "Organic",
            label: "Organic",
            iconClass: ["fas", "cog"],
            color: "yellow"
          }
        ],
        optionType: "icon-text-option",
        fillOptions: true,
        conditionals: [
          { matches: "Traditional", trigger: "mosquito-traditional" },
          { matches: "Organic", trigger: "mosquito-organic" }
        ]
      },
      //// question 2
      {
        id: "get-current-happiness",
        component: {
          type: "rating",
          placeholder: 3
        },
        waitAction: true,
        setVariable: "lawnHappiness",
        trigger: "9orless-lawn"
      },

      /// question 3
      {
        id: "service-provider-missing",
        options: [
          {
            value: "Customer Service",
            label: "Customer Service",
            hideText: true
          },
          { value: "Better Quality", label: "Better Quality", hideText: true },
          { value: "Better Pricing", label: "Better Pricing", hideText: true }
        ],
        optionType: "default",
        setVariable: "whyNewProvider",
        trigger: "provider-frusturating"
      },
      /// question 4
      {
        id: "get-lawn-thickness",
        options: [
          {
            value: "Thick",
            label: "Thick",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-thick.jpg"
          },
          {
            value: "Patchy",
            label: "Patchy",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-patchy.jpg"
          },
          {
            value: "Thin",
            label: "Thin",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-thin.jpg"
          }
        ],
        setVariable: "lawnThickness",
        optionType: "image-text-option",
        trigger: "lawn-thickness-response"
      },
      /// question 5
      {
        id: "get-biggest-issue",
        options: [
          {
            value: "Broadleaf weeds",
            label: "Broadleaf weeds",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-broadleaf-weeds.jpg"
          },
          {
            value: "Stubborn Weeds",
            label: "Stubborn Weeds",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-stubborn-weeds.jpg"
          },
          {
            value: "Grubs",
            label: "Grubs",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-grubs.jpg"
          },
          {
            value: "Crabgrass",
            label: "Crabgrass",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-crabgrass.jpg"
          },
          {
            value: "Disease",
            label: "Disease",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-disease.jpg"
          },
          {
            value: "Shade",
            label: "Shade",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-shade.jpg"
          }
        ],
        setVariable: "lawnBiggestProblem",
        optionType: "image-text-option",
        fillOptions: true,
        trigger: "next-service"
      },
      /// question 6
      {
        id: "get-bugging-you",
        options: [
          {
            value: "Ants",
            label: "Ants",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/pest_ants.jpg"
          },
          {
            value: "Spiders",
            label: "Spiders",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/pest_spiders.jpg"
          },
          {
            value: "Roaches",
            label: "Roaches",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/pest_roaches.jpg"
          },
          {
            value: "Rodents",
            label: "Rodents",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/pest_rodents.jpeg"
          },
          {
            value: "Other",
            label: "Other",
            image:
              "https://lawnbot-assets.s3.us-east-2.amazonaws.com/pest_other.jpeg"
          }
        ],
        setVariable: "pestBiggestProblem",
        optionType: "image-text-option",
        fillOptions: true,
        trigger: "next-service"
      }
    ]
  }
];
