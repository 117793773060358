import React, { useState, useEffect, useCallback } from "react";

import {
  Container,
  makeStyles,
  createStyles,
  Breadcrumbs,
  Link,
  Button,
  Typography,
  Snackbar
} from "@material-ui/core";

import { API, graphqlOperation } from "aws-amplify";
import {
  createOffer,
  updateOffer,
  deleteOffer
} from "../../../graphql/mutations";
import { useParams } from "react-router-dom";
import Title from "../../dashboard/Title";
import { OfferInfoForm } from "./offer-info-form";
import { checkCompanyAfterFetch } from "../companyUtils";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap"
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    }
  })
);

export function CompanyOfferDetail(props) {
  const classes = useStyles();
  let { companyId, offerId } = useParams();

  const [snackOpened, openSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("Offer Updated");

  const [offer, setOffer] = useState({});
  const [programs, setPrograms] = useState([]);
  const [priceTables, setPriceTables] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const fetchCompany = useCallback(async () => {
    try {
      const fetch = await checkCompanyAfterFetch(companyId);
      setPrograms(fetch.data.getCompany.programs.items);
      setPriceTables(fetch.data.getCompany.priceTables.items);
      if (fetch.data.getCompany.offers.items.length) {
        const targetOffer = fetch.data.getCompany.offers.items.find(
          (i) => i.id === offerId
        );
        if (!targetOffer) {
          window.location = `/${companyId}/offers`;
        } else {
          try {
            const pjson = JSON.parse(targetOffer.programs);
            targetOffer.programs = pjson;
          } catch (e) {}
          setOffer(targetOffer);
          setLoaded(true);
        }
      } else {
        window.location = `/${companyId}/offers`;
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }, [companyId, offerId]);

  useEffect(() => {
    // fetch customer record here
    fetchCompany();
  }, [fetchCompany]);

  const deleteOfferCb = async () => {
    const ask = window.confirm("Are you Sure?");
    try {
      if (ask) {
        await API.graphql(
          graphqlOperation(deleteOffer, { input: { id: offerId } })
        );
        window.location = `/company/${companyId}/offers`;
      }
    } catch (error) {
      console.log("error: ", error.errors[0].message);
    }
  };

  const updateOfferCb = async (offer) => {
    let insertOffer = { ...offer };
    insertOffer.programs = insertOffer.programs
      .filter((i) => i)
      .map((p) => {
        if (p.discountPriceTableId === "") {
          delete p["discountPriceTableId"];
        }
        return p;
      });

    insertOffer.programs = JSON.stringify(insertOffer.programs);

    const payload = Object.assign({}, insertOffer, {
      offerCompanyId: companyId
    });

    if (payload.createdAt) {
      delete payload.createdAt;
    }
    if (payload.updatedAt) {
      delete payload.updatedAt;
    }
    delete payload.__typename;

    try {
      const fetch = await API.graphql(
        graphqlOperation(updateOffer, { input: payload })
      );
      const id = fetch.data.updateOffer.id;
      openSnack(true);
    } catch (error) {
      console.log("error: ", error.errors[0].message);
    }
  };
  return (
    <Container className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
        <Link color="inherit" href={`/company/${companyId}`}>
          Company
        </Link>
        <Link color="inherit" href={`/company/${companyId}/offers`}>
          Offers
        </Link>
        <Typography color="textPrimary">{offer.name}</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <Title>Update Offer</Title>
      </div>
      <div className={classes.container}>
        <Button
          style={{ position: "relative", float: "right" }}
          color="secondary"
          variant="contained"
          onClick={deleteOfferCb}
        >
          Delete Offer
        </Button>
        {!loaded ? null : (
          <OfferInfoForm
            submit={updateOfferCb}
            programs={programs}
            priceTables={priceTables}
            offer={offer}
          />
        )}
      </div>
      <Snackbar
        open={snackOpened}
        autoHideDuration={1500}
        onClose={() => {
          openSnack(false);
          // window.location = `/company/${companyId}/offer/${offerId}`;
        }}
        message={snackMessage}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      ></Snackbar>
    </Container>
  );
}
