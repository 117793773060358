import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  makeStyles,
  createStyles,
  Breadcrumbs,
  Link,
  Typography,
  Snackbar,
  CircularProgress,
  MenuItem,
  Button,
  Select,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel
} from "@material-ui/core";
import { API } from "aws-amplify";
import { ClearDashboard } from "../components/ClearDashboard.js";
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap"
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    }
  })
);

export function LawnbotDashboardIntegration() {
  let { companyId } = useParams();
  const classes = useStyles();

  const [fetchingClient, setFetchingClient] = useState(true);
  const [clientId, setClientId] = useState("");
  const [cognitoUsers, setCognitoUsers] = useState([]);
  const [selectedCogUser, setSelectedCogUser] = useState("");
  const [snackOpened, openSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("Settings Saved");
  const [dashboardUsers, setDashboardUsers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [emailUser, setEmailUser] = useState("");
  const fetchClient = useCallback(async () => {
    const data = await API.get(
      "bridgeApi",
      `/dashboard/management/get-client?companyId=${companyId}`,
      {}
    );
    if (data.error) {
      console.log(data.message);
    } else {
      setClientId(data.clientId);
      setDashboardUsers(data.dashboardUsers ? data.dashboardUsers : []);
      setCognitoUsers(data.users ? data.users : []);
      if (data.users && data.users[0]) {
        setSelectedCogUser(data.users[0]);
      }
      setFetchingClient(false);
      setSnackMessage("User Created Successfully");
    }
  }, [companyId]);
  const resetUserPassword = async () => {
    try {
      const data = await API.post(
        "bridgeApi",
        `/dashboard/management/reset-password`,
        {
          body: { userId: emailUser.email }
        }
      ).catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          throw Error(err.response.data.message);
        } else {
          console.log(err);
        }
      });

      if (data.error) {
        setSnackMessage("Error: " + data.message);
      } else {
        setSnackMessage("User's password has been reset.");
      }
    } catch (error) {
      console.log(error);
      setSnackMessage(`${error}`);
    }

    openSnack(true);
  };
  const becomeClient = async () => {
    try {
      const data = await API.post("bridgeApi", `/dashboard/management/become`, {
        body: { targetClientId: clientId, userid: selectedCogUser.value }
      }).catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          throw Error(err.response.data.message);
        } else {
          console.log(err);
        }
      });

      if (data.error) {
        setSnackMessage("Error: " + data.message);
      } else {
        setSnackMessage("You can now log in under this client.");
      }
    } catch (error) {
      console.log(error);
      setSnackMessage(`${error}`);
    }

    openSnack(true);
  };
  const createClient = async () => {
    setFetchingClient(true);
    const data = await API.post(
      "bridgeApi",
      `/dashboard/management/create-client`,
      {
        headers: {
          "Content-Type": "application/json"
        },
        body: { companyId }
      }
    );

    if (data.error) {
      setSnackMessage("Error: " + data.message);
    } else {
      if (data.clientId) {
        setClientId(data.clientId);
      }

      setSnackMessage("Client added successfully");
    }

    openSnack(true);
    setFetchingClient(false);
  };

  useEffect(() => {
    fetchClient();
  }, [fetchClient]);

  const createUserCb = async () => {
    try {
      const roles = [];
      if (isAdmin) {
        roles.push("Admin");
      } else {
        roles.push("User");
      }
      const newUser = { name: userName, email: userEmail };
      const user = await API.post(
        "bridgeApi",
        "/dashboard/management/add-user",
        {
          body: {
            ...newUser,
            roles: JSON.stringify(roles),
            targetClientId: clientId
          }
        }
      ).catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          throw Error(err.response.data.message);
        } else {
          console.log(err);
        }
      });

      if (user && user.error) {
        setSnackMessage(`${user.message}`);
      } else {
        setIsAdmin(false);
        setUserEmail("");
        setUserName("");
        setSnackMessage("User added successfully");
      }
    } catch (e) {
      console.log(e);
      setSnackMessage(`${e}`);
    }

    openSnack(true);
  };

  return (
    <Container className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
        <Link color="inherit" href={`/company/${companyId}`}>
          Company
        </Link>

        <Typography color="textPrimary">ServiceBot Dashboard</Typography>
      </Breadcrumbs>

      <div className={classes.container}>
        <h3>Tools</h3>
        <hr />
        <div
          style={{ display: "flex", flexDirection: "column", marginTop: 15 }}
        >
          {!fetchingClient && !clientId && (
            <Button
              disabled={fetchingClient || clientId}
              color="primary"
              variant="contained"
              style={{ marginTop: 5, marginBottom: 5 }}
              onClick={createClient}
            >
              Create Client
            </Button>
          )}
          {fetchingClient ? (
            <div className={classes.modal}>
              <div className={classes.insideModal}>
                <CircularProgress />
                <h3>Gathering dashboard data...</h3>
              </div>
            </div>
          ) : (
            clientId && (
              <div>
                <div style={{ width: "100%", marginLeft: 10 }}>
                  <h4>Access Dashboard Client</h4>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 50,
                    marginTop: 50
                  }}
                >
                  <Select
                    labelid="cognitoUser"
                    id="user-select"
                    style={{ marginRight: 60, marginLeft: 10, minWidth: 200 }}
                    value={selectedCogUser}
                    onChange={(e) => {
                      setSelectedCogUser(e.target.value);
                    }}
                  >
                    {cognitoUsers.map((i) => {
                      const key =
                        "menu-item-" + Math.floor(Math.random() * 100000);
                      return (
                        <MenuItem key={key} value={i}>
                          {i.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <Button
                    disabled={fetchingClient || !clientId}
                    color="primary"
                    variant="contained"
                    onClick={becomeClient}
                  >
                    Become
                  </Button>
                </div>
                <hr />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: 250,
                    marginBottom: 50,
                    marginLeft: 10
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <h4>Add Dashboard User</h4>
                  </div>
                  <TextField
                    onChange={(e) => setUserEmail(e.target.value)}
                    variant="standard"
                    label="Email"
                  />
                  <TextField
                    style={{ marginTop: 20, marginBottom: 20 }}
                    variant="standard"
                    onChange={(e) => setUserName(e.target.value)}
                    label="Name"
                  />
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isAdmin}
                          onChange={(e) => setIsAdmin(!isAdmin)}
                        />
                      }
                      label="Admin"
                    />
                  </FormGroup>
                  <Button
                    style={{
                      marginTop: 15
                    }}
                    onClick={createUserCb}
                    disabled={!userName || !userEmail}
                    color="primary"
                    variant="contained"
                  >
                    Add User
                  </Button>
                </div>
                <hr />
                <div style={{ marginTop: 15 }}>
                  <div style={{ width: "100%", marginLeft: 10 }}>
                    <h4>Reset Dashboard Password</h4>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 25,
                      marginTop: 25
                    }}
                  >
                    <Select
                      labelid="cognitoUser"
                      id="user-select"
                      style={{ marginRight: 60, marginLeft: 10, minWidth: 200 }}
                      value={emailUser}
                      onChange={(e) => {
                        setEmailUser(e.target.value);
                      }}
                    >
                      {dashboardUsers.map((i) => {
                        const key =
                          "menu-item-" + Math.floor(Math.random() * 100000);
                        return (
                          <MenuItem key={key} value={i}>
                            {i.email}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <Button
                      disabled={fetchingClient || !clientId}
                      color="primary"
                      variant="contained"
                      onClick={resetUserPassword}
                    >
                      Reset Dashboard Password
                    </Button>
                  </div>
                </div>
                <hr />
                <ClearDashboard companyId={companyId} />
              </div>
            )
          )}
        </div>
      </div>
      <Snackbar
        open={snackOpened}
        autoHideDuration={2000}
        onClose={() => {
          openSnack(false);
        }}
        message={snackMessage}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      ></Snackbar>
    </Container>
  );
}
