import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import PropTypes from "prop-types";
import { Typography, Button } from "@material-ui/core";

import { CheckCircle, BlockRounded } from "@material-ui/icons";

export default function OffersListTable(props) {
  const { companyId, offers, classes } = props;

  return (
    <React.Fragment>
      <div classes={classes.root}>
        {offers.length ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Active</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {offers.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.startDate}</TableCell>
                  <TableCell>{row.endDate}</TableCell>
                  <TableCell>
                    {row.active ? <CheckCircle /> : <BlockRounded />}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        (window.location = `/company/${companyId}/offer/${row.id}`)
                      }
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography>No Offers</Typography>
        )}
      </div>
    </React.Fragment>
  );
}

OffersListTable.propTypes = {
  companyId: PropTypes.string,
  classes: PropTypes.object,
  offers: PropTypes.array
};
