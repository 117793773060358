import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, makeStyles, createStyles, Button } from "@material-ui/core";

import Title from "../../dashboard/Title";
import { API, graphqlOperation } from "aws-amplify";
import { BotInfoForm } from "./bot-info-form";
import { updateBot } from "../../../graphql/mutations";
import { getBotVersioned } from "../../../graphql/queries";

import CodeBlock from "@tenon-io/tenon-codeblock";
import { generateCode } from "./init-code";
import { learnMoreTemplate } from "./templates/learn-more.template";

import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import { objectionsTemplate } from "./templates/objections.template";
import { createNewVersion } from "../../../utils/versionUtilFuncs";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap"
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    }
  })
);

export function CompanyBotLearnMore(props) {
  let { companyId, botId } = useParams();
  const classes = useStyles();

  const [bot, setBot] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [learnMore, setLearnMore] = useState({});

  const fetchBot = async () => {
    try {
      const fetch = await API.graphql(
        graphqlOperation(getBotVersioned, { id: botId, version: "v0" })
      );
      if (fetch.data.getBotVersioned.learnMore) {
        try {
          fetch.data.getBotVersioned.learnMore = JSON.parse(
            fetch.data.getBotVersioned.learnMore
          );
        } catch (e) {
          fetch.data.getBotVersioned.learnMore = JSON.parse(
            learnMoreTemplate(fetch.data.getBotVersioned.company)
          );
        }
      } else {
        fetch.data.getBotVersioned.learnMore = JSON.parse(
          learnMoreTemplate(fetch.data.getBotVersioned.company)
        );
      }
      setBot(fetch.data.getBotVersioned);
      setLearnMore(fetch.data.getBotVersioned.learnMore);
      setLoaded(true);
    } catch (error) {
      console.log("error: ", error.errors[0].message);
    }
  };

  useEffect(() => {
    // fetch customer record here
    fetchBot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateBotCb = async () => {
    let payload = { ...bot, learnMore: JSON.stringify(learnMore, null, 2) };
    if (payload.learnMore.learnMore) {
      payload.learnMore = payload.learnMore.learnMore;
    }
    try {
      await createNewVersion(bot.id, "bot", payload);
      window.location = window.location + " ";
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return loaded ? (
    <Container className={classes.root}>
      <div className={classes.container}>
        <Title>{bot.botTitle} Learn More</Title>
      </div>
      <div className={classes.container}>
        <Button
          style={{ marginRight: 10, marginBottom: 20 }}
          onClick={updateBotCb}
          color="primary"
          variant="contained"
        >
          Save Learn More Question
        </Button>
        <Editor value={learnMore} onChange={(e) => setLearnMore(e)} />
      </div>
    </Container>
  ) : null;
}
