/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://5r7mp453lc.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "dashboardBridgeApi",
            "endpoint": process.env.REACT_APP_BRIDGE_API || "https://ymiosb7s1l.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "integrationapi",
            "endpoint": "https://n3xex3vlga.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": process.env.REACT_APP_BUILDER_GRAPHQL_ENDPOINT 
        || "https://dj2rvgqstvdlpkt37akgtl6nza.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": process.env.REACT_APP_BUILDER_IDENTITY_POOL 
        || "us-east-1:2e4e5c0f-4f19-4219-8825-4f0483efb7c7",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": process.env.REACT_APP_BUILDER_USER_POOL 
        || "us-east-1_td6zJmz0x",
    "aws_user_pools_web_client_id": process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID 
        || "499irj3ah68i1qbajplkk2mob5",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": process.env.REACT_APP_BUILDER_HOSTING_BUCKET 
        || "lawnbotreactbuilder-20200113112938-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": process.env.REACT_APP_BUILDER_CLOUDFRONT_DOMAIN 
        || "https://d2x4slx0441upb.cloudfront.net",
    "aws_user_files_s3_bucket": process.env.REACT_APP_BUILDER_USER_FILES_S3_BUCKET 
        || "lawnbotcompanystorage161827-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
