import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import PropTypes from "prop-types";
import { Typography, Button, TextField } from "@material-ui/core";

import * as _ from "lodash";
import useDebounce from "../../utils/debounce";

export default function CompanyListTable(props) {
  const { companies, classes } = props;
  const [allCompanies, setAllCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [filterText, setFilterText] = useState("");

  const debouncedSearchTerm = useDebounce(filterText, 500);

  useEffect(() => {
    setAllCompanies(companies);
    setFilteredCompanies(companies);
  }, [companies]);

  useEffect(() => {
    if (allCompanies.length) {
      const reg = new RegExp(filterText, "i");
      const comps = allCompanies.filter((i) => reg.test(i.companyName) || reg.test(i.customerName) || reg.test(i.customerEmail));
      setFilteredCompanies(comps);
    }
  }, [allCompanies, debouncedSearchTerm, filterText]);

  const stripImageMeta = (src) => {
    return src ? src.split("?")[0] : "";
  };

  const serachRecords = (e) => {
    setFilterText(e.target.value);
  };

  return (
    <React.Fragment>
      <div classes={classes.root}>
        <TextField
          onChange={serachRecords}
          placeholder="Search..."
          style={{
            margin: "0 auto",
            maxWidth: 900
          }}
        />
        {companies.length ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Contact Name</TableCell>
                <TableCell>Number of Bots</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCompanies.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    {row.branding ? (
                      <img
                        style={{ width: 80 }}
                        src={stripImageMeta(row.branding.companyLogo)}
                        alt=""
                      />
                    ) : null}
                  </TableCell>
                  <TableCell>{row.companyName}</TableCell>
                  <TableCell>{row.customerName}</TableCell>
                  {row.bots && row.bots.items && (
                    <TableCell>{row.bots.items.length}</TableCell>
                  )}

                  <TableCell align="right">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => (window.location = `/company/${row.id}`)}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography>No Companies</Typography>
        )}
      </div>
    </React.Fragment>
  );
}

CompanyListTable.propTypes = {
  customers: PropTypes.array,
  classes: PropTypes.object
};
