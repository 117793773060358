import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Container,
  makeStyles,
  createStyles,
  Button,
  MenuItem,
  TextField,
  Select,
  Snackbar,
  Breadcrumbs,
  Link,
  Typography,
  Modal
} from "@material-ui/core";
import Title from "../../dashboard/Title";
import { API, graphqlOperation } from "aws-amplify";
import { PriceTableInfoForm } from "./price-table-info-form";
import {
  updateProgramVersioned,
  createService,
  updateServiceVersioned,
  updatePriceTableRows,
  createPriceTableRows,
  deletePriceTableRows,
  updatePriceTableVersioned,
  deletePriceTable
} from "../../../graphql/mutations";
import {
  getProgramVersioned,
  listServiceTypes,
  getPriceTableVersioned,
  getCompanyVersioned
} from "../../../graphql/queries";

import { CSVLink, CSVDownload } from "react-csv";

import * as _ from "lodash";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import PropTypes from "prop-types";

import CSVReader from "react-csv-reader";
import Conditions from "../../../components/conditions/conditions";
import {
  createNewVersion,
  runKeyDeletion
} from "../../../utils/versionUtilFuncs";
import { ContactsOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap"
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    },
    addBtn: {
      position: "relative",
      float: "right",
      marginRight: 15
    },
    servicesContainer: {
      width: "70%",
      minHeight: 100,
      maxHeight: 500,
      border: "1px inset black",
      padding: 10,
      overflow: "auto",
      marginTop: 10
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "2px solid #000",
      boxShadow: theme.shadows[5]
    },
    insideModal: {
      alignItems: "center",
      backgroundColor: "#FAFAFA",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: theme.spacing(4, 4, 2, 4)
    }
  })
);

export function CompanyPriceTableDetail(props) {
  let { companyId, priceTableId } = useParams();
  const classes = useStyles();

  const [priceTable, setPriceTable] = useState({});
  const [priceTPrograms, setPriceTPrograms] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loaded2, setLoaded2] = useState(false);
  const [priceRows, setPriceRows] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [csvData, setCSVData] = useState([["Start", "Stop", "Price", "Units"]]);
  const [autoItem, setAutoItem] = useState({
    start: 1,
    end: 44,
    units: "1000 sqft",
    incrementPrice: 15.0,
    increment: 1,
    startPrice: 50
  });
  const [deleteModal, setDeleteModal] = useState(false);

  const buttonStyle = {
    margin: 20
  };

  const priceRowTemplate = {
    start: 0,
    end: 0,
    price: 0,
    units: `1000 sqft`
  };

  const addPriceRow = (data = {}) => {
    const add = Object.assign({}, priceRowTemplate, data);
    const payload = [...priceRows, add];
    setPriceRows(payload);
  };

  const showAutoGenForm = () => {
    const key = `autogen_32323324`;

    return (
      <TableRow key={key}>
        <TableCell>
          <TextField
            id="auto-start"
            label="Start Size"
            value={autoItem.start}
            style={{ margin: 8, width: 100 }}
            placeholder={"0"}
            margin="normal"
            onChange={(e) => {
              setAutoItem({ ...autoItem, start: e.target.value });
            }}
            onBlur={(e) => {
              setAutoItem({ ...autoItem, start: e.target.value });
            }}
            errorMessages={["this field is required"]}
            InputLabelProps={{
              shrink: true
            }}
            validators={["required"]}
          />
        </TableCell>
        <TableCell>
          <TextField
            id="auto-end"
            label="End Size"
            value={autoItem.end}
            style={{ margin: 8, width: 100 }}
            placeholder={"1000"}
            margin="normal"
            onChange={(e) => {
              setAutoItem({ ...autoItem, end: e.target.value });
            }}
            onBlur={(e) => {
              setAutoItem({ ...autoItem, end: e.target.value });
            }}
            errorMessages={["this field is required"]}
            InputLabelProps={{
              shrink: true
            }}
            validators={["required"]}
          />
        </TableCell>

        <TableCell>
          <TextField
            id="starting-price"
            label="Start Price"
            value={autoItem.startPrice}
            style={{ margin: 8, width: 100 }}
            placeholder={"1000"}
            margin="normal"
            onChange={(e) => {
              setAutoItem({ ...autoItem, startPrice: e.target.value });
            }}
            onBlur={(e) => {
              setAutoItem({ ...autoItem, startPrice: e.target.value });
            }}
            errorMessages={["this field is required"]}
            InputLabelProps={{
              shrink: true
            }}
            validators={["required"]}
          />
        </TableCell>

        <TableCell>
          <TextField
            id="auto-increment"
            label="Increment Size"
            value={autoItem.increment}
            style={{ margin: 8, width: 100 }}
            placeholder={"1000"}
            margin="normal"
            onChange={(e) => {
              setAutoItem({ ...autoItem, increment: e.target.value });
            }}
            onBlur={(e) => {
              setAutoItem({ ...autoItem, increment: e.target.value });
            }}
            errorMessages={["this field is required"]}
            InputLabelProps={{
              shrink: true
            }}
            validators={["required"]}
          />
        </TableCell>

        <TableCell>
          <TextField
            id="auto-price"
            label="Increment Price"
            value={autoItem.incrementPrice}
            style={{ margin: 8, width: 100 }}
            placeholder={"65.0"}
            margin="normal"
            onChange={(e) => {
              setAutoItem({ ...autoItem, incrementPrice: e.target.value });
            }}
            onBlur={(e) => {
              setAutoItem({ ...autoItem, incrementPrice: e.target.value });
            }}
            errorMessages={["this field is required"]}
            InputLabelProps={{
              shrink: true
            }}
            validators={["required"]}
          />
        </TableCell>
        <TableCell>
          <Select
            labelid="auto-units"
            id="auto-units"
            style={{ marginTop: 24, marginRight: 10, marginLeft: 10 }}
            value={autoItem.units}
            onChange={(e) => {
              const payload = Object.assign({}, autoItem, {
                units: e.target.value
              });
              setAutoItem(payload);
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
          >
            {["1 sqft", "1000 sqft", "acres", "zones", "flat"].map((i) => {
              const key = "menu-item-" + Math.floor(Math.random() * 100000);
              return (
                <MenuItem key={key} value={i}>
                  {i}
                </MenuItem>
              );
            })}
          </Select>
        </TableCell>
        <TableCell>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={autoGenPriceRows}
          >
            Generate
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  const history = useHistory();

  const fetchPriceTable = async () => {
    try {
      const fetch = await API.graphql(
        graphqlOperation(getPriceTableVersioned, {
          id: priceTableId,
          version: "v0"
        })
      );
      setPriceTable(fetch.data.getPriceTableVersioned);
      const sorted = fetch.data.getPriceTableVersioned.rows.items
        ? fetch.data.getPriceTableVersioned.rows.items.sort(
            (a, b) => a.start <= b.start
          )
        : [];
      const rows = [...csvData];
      setPriceRows(sorted.sort().reverse());
      let newsort = [...sorted];
      newsort = _.sortBy(newsort, "start");
      newsort.forEach((r) => {
        const row = [
          parseFloat(r["start"]),
          parseFloat(r["end"]),
          parseFloat(r["price"]),
          r["units"]
        ];
        rows.push(row);
      });
      setCSVData(rows);
      setConditions(
        fetch.data.getPriceTableVersioned.conditions
          ? JSON.parse(fetch.data.getPriceTableVersioned.conditions)
          : []
      );
      // setServices(fetch.data.getProgram.services.items);
      setLoaded(true);
      findPriceTablePrograms();
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const findPriceTablePrograms = async () => {
    try {
      const fetchedResults = await API.graphql(
        graphqlOperation(getCompanyVersioned, {
          id: companyId,
          version: "v0",
          limit: 10000,
          sortDirection: "ASC"
        })
      );
      const companyPrograms =
        fetchedResults.data.getCompanyVersioned.programs.items;
      const programsForPriceTable = companyPrograms
        ? companyPrograms.filter((program) => {
            return program.services
              ? program.services.items.some(
                  (item) => item.priceTableId === priceTableId
                )
              : false;
          })
        : [];

      setPriceTPrograms(programsForPriceTable);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const deletePriceTableCheck = () => {
    setDeleteModal(true);
  };

  const deleteCurrentPriceTable = async () => {
    try {
      await API.graphql(
        graphqlOperation(updatePriceTableVersioned, {
          input: { id: priceTableId, version: "v0", isDeleted: true }
        })
      );
      setSnackMessage("Price Table Deleted");
      openSnack(true);
      setDeleteModal(false);
      history.goBack();
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const [serviceTypes, setServiceTypes] = useState([]);
  const [snackOpened, openSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("Program Saved");

  const fetchServiceTypes = async () => {
    try {
      const fetch = await API.graphql(graphqlOperation(listServiceTypes));
      setServiceTypes(fetch.data.listServiceTypes.items);
      setLoaded2(true);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    // fetch customer record here
    fetchPriceTable();
    fetchServiceTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePriceTableCb = async (pt) => {
    pt.conditions = JSON.stringify(conditions);
    try {
      if (pt.id) {
        delete pt.company;
        delete pt.rows;
        await createNewVersion(pt.id, "priceTable", pt);
        setSnackMessage("Price Table Updated");
        openSnack(true);
      } else {
        let payload = { ...pt, programCompanyId: companyId };
        await createNewVersion(pt.id, "priceTable", payload);
        setSnackMessage("Price Table Saved");
        openSnack(true);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const cvsUploadPriceRows = (data = [], fileName) => {
    const newRows = [];
    data.shift();
    data.forEach((r) => {
      const p = Object.assign(
        {},
        {
          start: parseFloat(r[0]),
          end: parseFloat(r[1]),
          price: parseFloat(r[2]),
          units: r[3]
        }
      );
      console.log("p: ", p);
      addPriceRowCb(p);
      // newRows.push(p);
    });
    // const insertRows = [...priceRows, ...newRows];
    // console.log(insertRows);
    // setPriceRows(insertRows);
  };

  const autoGenPriceRows = () => {
    let tracker = 0;
    console.log(autoItem);
    const rowItems = [];

    for (
      let i = parseFloat(autoItem.start);
      i < parseFloat(autoItem.end);
      i += parseFloat(autoItem.increment)
    ) {
      // console.log(i, i+ parseFloat(autoItem.increment), ( parseFloat(autoItem.startPrice) + (parseFloat(autoItem.incrementPrice) * tracker)) , autoItem.units);

      // if (tracker > 1) break;
      addPriceRowCb({
        start: i,
        end: i + parseFloat(autoItem.increment),
        price:
          parseFloat(autoItem.startPrice) +
          parseFloat(autoItem.incrementPrice) * tracker,
        units: autoItem.units
      });

      tracker++;
    }
    setPriceRows(rowItems);
  };

  const deletePriceRowCb = async (prow) => {
    try {
      if (prow.id) {
        await API.graphql(
          graphqlOperation(deletePriceTableRows, { input: { id: prow.id } })
        );
        setSnackMessage("Price Row Deleted");
        openSnack(true);
        const fpriceRows = priceRows.filter((i) => i.id !== prow.id);
        setPriceRows(fpriceRows);
      } else {
        const fpriceRows = priceRows.filter((i) => !i.id);
        setPriceRows(fpriceRows);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const deleteAllPriceRows = (e) => {
    const areyousure = window.confirm("Are you sure you want to do this?");
    if (areyousure) {
      priceRows.forEach((r) => {
        deletePriceRowCb(r);
      });
    }
  };

  const addPriceRowCb = async (prow) => {
    if (prow.start) prow.start = parseFloat(prow.start);
    if (prow.end) prow.end = parseFloat(prow.end);
    if (prow.price) prow.price = parseFloat(prow.price);
    runKeyDeletion(prow, "");
    try {
      if (prow.id) {
        delete prow.__typename;
        await API.graphql(
          graphqlOperation(updatePriceTableRows, { input: prow })
        );
        setSnackMessage("Price Row Updated");
        openSnack(true);
        setTimeout(() => {
          window.location = window.location + "";
        }, 500);
      } else {
        const pp = _.pick(prow, "start", "end", "price", "units");
        const payload = Object.assign({}, pp, {
          priceTableRowsPriceTableId: priceTableId
        });

        delete payload.__typename;
        await API.graphql(
          graphqlOperation(createPriceTableRows, { input: payload })
        );
        setSnackMessage("Price Row Created");
        openSnack(true);
        setTimeout(() => {
          window.location = window.location + "";
        }, 3500);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const deleteModalJSX = (
    <div className={classes.modal}>
      {priceTPrograms.length > 0 ? (
        <div className={classes.insideModal}>
          <h3>
            {
              "This price table can't be deleted until these associated programs are removed."
            }
          </h3>
          <ul>
            {priceTPrograms.map((prg, i) => (
              <li key={i} style={{ marginRight: 25 }}>
                {prg.programName}
              </li>
            ))}
          </ul>
          <div>
            <Button
              type="button"
              color="primary"
              variant="contained"
              style={buttonStyle}
              onClick={() => setDeleteModal(false)}
            >
              Okay
            </Button>
          </div>
        </div>
      ) : (
        <div className={classes.insideModal}>
          <h3>Are you sure you want to delete this price table?</h3>
          <div>
            <Button
              type="button"
              color="primary"
              variant="contained"
              style={buttonStyle}
              onClick={deleteCurrentPriceTable}
            >
              Yes
            </Button>
            <Button
              type="button"
              color="delete"
              variant="contained"
              style={buttonStyle}
              onClick={() => setDeleteModal(false)}
            >
              No
            </Button>
          </div>
        </div>
      )}
    </div>
  );

  return loaded && loaded2 ? (
    <Container className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
        <Link color="inherit" href={`/company/${companyId}`}>
          Company
        </Link>
        <Link color="inherit" href={`/company/${companyId}/price-tables`}>
          Price Tables
        </Link>
        <Typography color="textPrimary">{priceTable.name}</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <Title>{priceTable.name}</Title>
        <PriceTableInfoForm
          submit={updatePriceTableCb}
          deletePriceTableCheck={deletePriceTableCheck}
          priceTable={priceTable}
        />
        <Modal
          disableBackdropClick
          open={deleteModal}
          className={classes.modal}
        >
          {deleteModalJSX}
        </Modal>
      </div>
      <div className={classes.container}>
        Auto Generator
        {autoItem ? showAutoGenForm() : null}
      </div>
      <div className={classes.container}>
        <Button
          className={classes.addBtn}
          color="primary"
          variant="contained"
          onClick={addPriceRow}
        >
          Add Price Row
        </Button>
        <Button
          className={classes.addBtn}
          color="danger"
          variant="contained"
          onClick={deleteAllPriceRows}
        >
          Delete All Price Rows
        </Button>
        <CSVReader onFileLoaded={cvsUploadPriceRows} /> <br />
        <CSVLink
          data={csvData}
          filename={
            `${priceTable.name.replace(/\s/g, "_")}` +
            "_price_table_download.csv"
          }
        >
          Download Price Table
        </CSVLink>
        <br />
        <br />
        <Title>Price Rows</Title>
        <PriceTableList
          serviceTypes={serviceTypes}
          priceRows={priceRows}
          submit={addPriceRowCb}
          deleteRow={deletePriceRowCb}
        />
      </div>
      <div className={classes.container}>
        <Conditions
          conditions={conditions}
          updatePriceTableConditions={(conditions) => {
            setConditions(conditions);
          }}
        />
      </div>
      <Snackbar
        open={snackOpened}
        autoHideDuration={6000}
        onClose={() => {
          openSnack(false);
        }}
        message={snackMessage}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      ></Snackbar>
    </Container>
  ) : null;
}

function PriceTableList(props) {
  const { priceRows } = props;
  return (
    <Container>
      <TableHead>
        <TableRow>
          <TableCell>Start</TableCell>
          <TableCell>End</TableCell>
          <TableCell>Price</TableCell>
          <TableCell>Units</TableCell>
          <TableCell align="right"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {_.sortBy(priceRows, "start").map((row) => {
          const key =
            "price-table-row-item-" + Math.round(Math.random() * 100000);
          return <PriceTableRow priceRow={row} {...props} key={key} />;
        })}
      </TableBody>
    </Container>
  );
}

function PriceTableRow(props) {
  const { priceRow } = props;

  let status = "Draft";

  if (priceRow.id) {
    status = "Saved";
  }

  const [item, setItem] = useState(
    priceRow || {
      start: 0,
      end: 0,
      units: "1000 sqft",
      price: 0
    }
  );

  const updateForm = (e) => {
    const key = e.target.id;
    const val = e.target.value;
    const payload = {};
    payload[key] = val;
    setItem({ ...item, ...payload });
  };

  const submitForm = (e) => {
    props.submit(item);
  };

  const deleteForm = (e) => {
    props.deleteRow(item);
  };
  const key = "service-row-item-" + Math.floor(Math.round() * 100000);

  return (
    <TableRow key={key}>
      <TableCell>
        <TextField
          id="start"
          label="Start Size"
          value={item.start}
          style={{ margin: 8, width: 200 }}
          placeholder={"0"}
          margin="normal"
          onChange={updateForm}
          onBlur={updateForm}
          errorMessages={["this field is required"]}
          InputLabelProps={{
            shrink: true
          }}
          validators={["required"]}
        />
      </TableCell>
      <TableCell>
        <TextField
          id="end"
          label="End Size"
          value={item.end}
          style={{ margin: 8, width: 200 }}
          placeholder={"1000"}
          margin="normal"
          onChange={updateForm}
          onBlur={updateForm}
          errorMessages={["this field is required"]}
          InputLabelProps={{
            shrink: true
          }}
          validators={["required"]}
        />
      </TableCell>
      <TableCell>
        <TextField
          id="price"
          label="Price"
          value={item.price}
          style={{ margin: 8, width: 200 }}
          placeholder={"65.0"}
          margin="normal"
          onChange={updateForm}
          onBlur={updateForm}
          errorMessages={["this field is required"]}
          InputLabelProps={{
            shrink: true
          }}
          validators={["required"]}
        />
      </TableCell>
      <TableCell>
        <Select
          labelid="units"
          id="units"
          style={{ marginTop: 24, marginRight: 10, marginLeft: 10 }}
          value={item.units}
          onChange={(e) => {
            const payload = Object.assign({}, item, { units: e.target.value });
            setItem(payload);
          }}
          errorMessages={["this field is required"]}
          validators={["required"]}
        >
          {["1 sqft", "1000 sqft", "acres", "zones", "flat", "1 linear ft"].map(
            (i) => {
              const key = "menu-item-" + Math.floor(Math.random() * 100000);
              return (
                <MenuItem key={key} value={i}>
                  {i}
                </MenuItem>
              );
            }
          )}
        </Select>
      </TableCell>
      <TableCell>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          onClick={submitForm}
        >
          Save
        </Button>
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          onClick={deleteForm}
        >
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
}

PriceTableRow.propTypes = {
  service: PropTypes.object,
  submit: PropTypes.func,
  deleteRow: PropTypes.func
};
