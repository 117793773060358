import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  makeStyles,
  createStyles,
  TextField,
  Button,
  Breadcrumbs,
  Link,
  Typography,
  Snackbar
} from "@material-ui/core";

import Title from "../dashboard/Title";
import { API, graphqlOperation } from "aws-amplify";
import {
  updateCompanyDataServices,
  createCompanyDataServices,
  updateCompany
} from "../../graphql/mutations";
import { checkCompanyAfterFetch } from "./companyUtils";
import { createNewVersion, runKeyDeletion } from "../../utils/versionUtilFuncs";
import { SalesCenterDataServicesFormFields } from "./components/SalesCenterDataServicesFormFields";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap"
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    }
  })
);

export function CompanyDataServices(props) {
  let { companyId } = useParams();
  const classes = useStyles();

  const [company, setCompany] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [dataServices, setDataServices] = useState({});

  const [snackOpened, openSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("Settings Saved");

  const fetchCompany = useCallback(async () => {
    try {
      const fetch = await checkCompanyAfterFetch(companyId);
      setCompany(fetch.data.getCompany);
      setDataServices(
        fetch.data.getCompany.dataServices || {
          stripeTestSecret: "",
          stripeSecret: "",
          realGreenAPIKey: "",
          zillowAPIKey: "",
          airTableTableName: "",
          airTableBase: "",
          googleSheetsClientEmail: "",
          googleSheetsPrivateKey: "",
          servmanApiAuth: "",
          servmanApiKey: "",
          servmanApiUrl: ""
        }
      );
      setLoaded(true);
    } catch (error) {
      console.log("error: ", error.errors[0].message);
    }
  }, [companyId]);

  useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);

  const saveDataServices = async () => {
    try {
      if (dataServices.id) {
        runKeyDeletion(dataServices, "dataServices");
        await API.graphql(
          graphqlOperation(updateCompanyDataServices, { input: dataServices })
        );
        openSnack(true);
      } else {
        const payload = { ...dataServices };
        payload["companyDataServicesCompanyId"] = companyId;
        delete payload.__typename;

        const br = await API.graphql(
          graphqlOperation(createCompanyDataServices, { input: payload })
        );
        const bid = br.data.createCompanyDataServices.id;
        setDataServices(br.data.createCompanyDataServices);
        const cpayload = { id: company.id, companyDataServicesId: bid };
        // cpayload["companyCompanyBrandingId"] = bid;
        await createNewVersion(companyId, "company", cpayload);
        openSnack(true);
      }
    } catch (error) {
      console.log("error: ", error.errors[0].message);
    }
  };

  return (
    <Container className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
        <Link color="inherit" href={`/company/${companyId}`}>
          Company
        </Link>

        <Typography color="textPrimary">Data Services</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <Title>Data Services</Title>
        <div>
          <TextField
            id="stripeTestSecret"
            label="Stripe Test Secret"
            value={dataServices.stripeTestSecret}
            style={{ margin: 8, width: 200 }}
            placeholder={"XXXXX"}
            margin="normal"
            onChange={(e) => {
              setDataServices({
                ...dataServices,
                stripeTestSecret: e.target.value
              });
            }}
            onBlur={(e) => {
              setDataServices({
                ...dataServices,
                stripeTestSecret: e.target.value
              });
            }}
            errorMessages={["this field is required"]}
            InputLabelProps={{
              shrink: true
            }}
            validators={["required"]}
          />
        </div>

        <div>
          <TextField
            id="stripeSecret"
            label="Stripe Live Secret"
            value={dataServices.stripeSecret}
            style={{ margin: 8, width: 200 }}
            placeholder={"XXXXXXX"}
            margin="normal"
            onChange={(e) => {
              setDataServices({
                ...dataServices,
                stripeSecret: e.target.value
              });
            }}
            onBlur={(e) => {
              setDataServices({
                ...dataServices,
                stripeSecret: e.target.value
              });
            }}
            errorMessages={["this field is required"]}
            InputLabelProps={{
              shrink: true
            }}
            validators={["required"]}
          />
        </div>

        <div>
          <TextField
            id="realGreenAPIKey"
            label="Real Green API Key"
            value={dataServices.realGreenAPIKey}
            style={{ margin: 8, width: 200 }}
            placeholder={"XXXXXXX"}
            margin="normal"
            onChange={(e) => {
              setDataServices({
                ...dataServices,
                realGreenAPIKey: e.target.value
              });
            }}
            onBlur={(e) => {
              setDataServices({
                ...dataServices,
                realGreenAPIKey: e.target.value
              });
            }}
            errorMessages={["this field is required"]}
            InputLabelProps={{
              shrink: true
            }}
            validators={["required"]}
          />
        </div>
        <div>
          <TextField
            id="serviceAssistantId"
            label="Service Assistant ID"
            value={dataServices.serviceAssistantId}
            style={{ margin: 8, width: 200 }}
            placeholder={""}
            margin="normal"
            onChange={(e) => {
              setDataServices({
                ...dataServices,
                serviceAssistantId: e.target.value
              });
            }}
            onBlur={(e) => {
              setDataServices({
                ...dataServices,
                serviceAssistantId: e.target.value
              });
            }}
            errorMessages={["this field is required"]}
            InputLabelProps={{
              shrink: true
            }}
            validators={["required"]}
          />
        </div>
        <div>
          <TextField
            id="zillowAPIKey"
            label="Zillow API Key"
            value={dataServices.zillowAPIKey}
            style={{ margin: 8, width: 200 }}
            placeholder={"XXXXXXX"}
            margin="normal"
            onChange={(e) => {
              setDataServices({
                ...dataServices,
                zillowAPIKey: e.target.value
              });
            }}
            onBlur={(e) => {
              setDataServices({
                ...dataServices,
                zillowAPIKey: e.target.value
              });
            }}
            errorMessages={["this field is required"]}
            InputLabelProps={{
              shrink: true
            }}
            validators={["required"]}
          />
        </div>
        <div>
          <TextField
            id="airTableBase"
            label="AirTable Base Name"
            value={dataServices.airTableBase}
            style={{ margin: 8, width: 200 }}
            placeholder={"XXXXXXX"}
            margin="normal"
            onChange={(e) => {
              setDataServices({
                ...dataServices,
                airTableBase: e.target.value
              });
            }}
            onBlur={(e) => {
              setDataServices({
                ...dataServices,
                airTableBase: e.target.value
              });
            }}
            errorMessages={["this field is required"]}
            InputLabelProps={{
              shrink: true
            }}
            validators={["required"]}
          />
        </div>
        <div>
          <TextField
            id="airTableTableName"
            label="AirTable Table Name"
            value={dataServices.airTableTableName}
            style={{ margin: 8, width: 200 }}
            placeholder={"XXXXXXX"}
            margin="normal"
            onChange={(e) => {
              setDataServices({
                ...dataServices,
                airTableTableName: e.target.value
              });
            }}
            onBlur={(e) => {
              setDataServices({
                ...dataServices,
                airTableTableName: e.target.value
              });
            }}
            errorMessages={["this field is required"]}
            InputLabelProps={{
              shrink: true
            }}
            validators={["required"]}
          />
        </div>
        <div>
          <TextField
            id="googleSheetsClientEmail"
            label="Google Sheets Client Email"
            value={dataServices.googleSheetsClientEmail}
            style={{ margin: 8, width: 200 }}
            placeholder={"XXXXXXX"}
            margin="normal"
            onChange={(e) => {
              setDataServices({
                ...dataServices,
                googleSheetsClientEmail: e.target.value
              });
            }}
            onBlur={(e) => {
              setDataServices({
                ...dataServices,
                googleSheetsClientEmail: e.target.value
              });
            }}
            errorMessages={["this field is required"]}
            InputLabelProps={{
              shrink: true
            }}
            validators={["required"]}
          />
        </div>
        <div>
          <TextField
            id="googleSheetsPrivateKey"
            label="Google Sheets Private Key"
            value={dataServices.googleSheetsPrivateKey}
            style={{ margin: 8, width: 200 }}
            placeholder={"XXXXXXX"}
            margin="normal"
            onChange={(e) => {
              setDataServices({
                ...dataServices,
                googleSheetsPrivateKey: e.target.value
              });
            }}
            onBlur={(e) => {
              setDataServices({
                ...dataServices,
                googleSheetsPrivateKey: e.target.value
              });
            }}
            errorMessages={["this field is required"]}
            InputLabelProps={{
              shrink: true
            }}
            validators={["required"]}
          />
        </div>
        <div>
          <TextField
            id="servmanApiKey"
            label="Servman API Key"
            value={dataServices.servmanApiKey}
            style={{ margin: 8, width: 200 }}
            placeholder={"XXXXXXX"}
            margin="normal"
            onChange={(e) => {
              setDataServices({
                ...dataServices,
                servmanApiKey: e.target.value
              });
            }}
            onBlur={(e) => {
              setDataServices({
                ...dataServices,
                servmanApiKey: e.target.value
              });
            }}
            errorMessages={["this field is required"]}
            InputLabelProps={{
              shrink: true
            }}
            validators={["required"]}
          />
        </div>
        <div>
          <TextField
            id="servmanApiAuth"
            label="Servman Auth Key"
            value={dataServices.servmanApiAuth}
            style={{ margin: 8, width: 200 }}
            placeholder={"XXXXXXX"}
            margin="normal"
            onChange={(e) => {
              setDataServices({
                ...dataServices,
                servmanApiAuth: e.target.value
              });
            }}
            onBlur={(e) => {
              setDataServices({
                ...dataServices,
                servmanApiAuth: e.target.value
              });
            }}
            errorMessages={["this field is required"]}
            InputLabelProps={{
              shrink: true
            }}
            validators={["required"]}
          />
        </div>
        <div>
          <TextField
            id="servmanApiUrl"
            label="Servman Api Url"
            value={dataServices.servmanApiUrl}
            style={{ margin: 8, width: 200 }}
            placeholder={"https://api.servman.net/v1/"}
            margin="normal"
            onChange={(e) => {
              setDataServices({
                ...dataServices,
                servmanApiUrl: e.target.value
              });
            }}
            onBlur={(e) => {
              setDataServices({
                ...dataServices,
                servmanApiUrl: e.target.value
              });
            }}
            errorMessages={["this field is required"]}
            InputLabelProps={{
              shrink: true
            }}
            validators={["required"]}
          />
        </div>
        <div>
          <SalesCenterDataServicesFormFields
            company={company}
            dataServices={dataServices}
            setDataServices={setDataServices}
          />
        </div>
        <div style={{ paddingTop: "16px" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={saveDataServices}
          >
            Save
          </Button>
        </div>
      </div>
      <Snackbar
        open={snackOpened}
        autoHideDuration={2000}
        onClose={() => {
          openSnack(false);
        }}
        message={snackMessage}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      ></Snackbar>
    </Container>
  );
}
