import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { API, graphqlOperation } from "aws-amplify";
import { getProgramVersioned } from "../../../../graphql/queries";
import { Button, Container } from "@material-ui/core";

import { useParams } from "react-router-dom";
import Title from "../../../dashboard/Title";
import { JsonEditor as Editor } from "jsoneditor-react";
import { updateProgram } from "../../../../graphql/mutations";
import { templatedLawnCare } from "./templates/probing_questions_lawn";
import { templatedIrrigation } from "./templates/probing_questions_irrigation";
import { templatedPets } from "./templates/probing_questions_pet_waste";
import { templatedPests } from "./templates/probing_questions_pests";
import { templatedMosquito } from "./templates/probing_questions_mosquito";
import {
  createNewVersion,
  createInitialVersions,
  createInitialId
} from "../../../../utils/versionUtilFuncs";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%"
  },
  newButton: {
    position: "relative",
    float: "right",
    marginRight: 20
  }
}));

export default function ProgramProbingQuestionsPage(props) {
  let { programId } = useParams();
  const classes = useStyles();

  const [program, setProgram] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [probingQuestions, setProbingQuestions] = useState([]);

  useEffect(() => {
    fetchProgram();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProgram = async () => {
    try {
      const fetch = await API.graphql(
        graphqlOperation(getProgramVersioned, { id: programId, version: "v0" })
      );

      setProgram(fetch.data.getProgramVersioned);
      if (fetch.data.getProgramVersioned.probingQuestionsJson !== null) {
        const pb = JSON.parse(
          fetch.data.getProgramVersioned.probingQuestionsJson
        );
        setProbingQuestions(pb);
      } else {
        setProbingQuestions(
          setProgramTypeTemplateQuestions(
            fetch.data.getProgramVersioned.programType
          )
        );
      }
    } catch (e) {
      setProbingQuestions(
        setProgramTypeTemplateQuestions(
          fetch.data.getProgramVersioned.programType
        )
      );
    }

    setLoaded(true);
  };

  const setProgramTypeTemplateQuestions = (type) => {
    let questions = [
      {
        id: "questions",
        steps: [],
        probing_questions: []
      }
    ];
    switch (type) {
      case "Mowing":
      case "Fertilizer & Weed Control":
      case "Lawn Seeding":
        questions = templatedLawnCare;
        break;
      case "Irrigation Services":
        questions = templatedIrrigation;
        break;
      case "Pest Control":
      case "Perimeter Pest Control":
        questions = templatedPests;
        break;
      case "Mosquito Control":
        questions = templatedMosquito;
        break;
      case "Pet Waste":
        questions = templatedPets;
        break;
      default:
    }
    return questions;
  };

  const updateProgamCb = async () => {
    let payload = {
      ...program,
      probingQuestionsJson: JSON.stringify(probingQuestions, null, 2)
    };
    try {
      await createNewVersion(program.id, "program", payload);
      window.location = window.location + " ";
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <Container classes={classes.root}>
      <div className={classes.container}>
        <Title>Probing Questions</Title>
        {/* <Button className={classes.newButton} color="primary" variant="contained" onClick={() => {window.location = '/company/new'}}>New +</Button> */}
        {loaded ? (
          <div>
            <Button
              style={{ marginRight: 10, marginBottom: 20 }}
              onClick={updateProgamCb}
              color="primary"
              variant="contained"
            >
              Save Program Probing Questions
            </Button>
            <Editor
              value={probingQuestions}
              onChange={(e) => setProbingQuestions(e)}
            />
          </div>
        ) : null}
      </div>
    </Container>
  );
}
