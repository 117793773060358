import React, { useState } from "react";
import {
  Container,
  makeStyles,
  TextField,
  createStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  LinearProgress,
  DialogTitle,
  Slide
} from "@material-ui/core";
import * as cloningFuncs from "./cloning.js";
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap"
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(1),
      width: "100%"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    },
    editorStyle: {
      border: "1px solid #ccc",
      borderRadius: 10,
      padding: 20
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "2px solid #000",
      boxShadow: theme.shadows[5]
    },
    insideModal: {
      alignItems: "center",
      backgroundColor: "#FAFAFA",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: theme.spacing(4, 4, 2, 4)
    }
  })
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export function CloningModal({ open, handleClose, companyId }) {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [cloning, setCloning] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState("");
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {error
          ? `${error}`
          : cloning
          ? `Cloning into ${name}`
          : "Enter the name of the cloned company."}
      </DialogTitle>
      <DialogContent>
        {cloning ? (
          <div style={{ marginBottom: 25 }}>
            <p>{progressMessage}</p>
            <LinearProgress variant="determinate" value={progress} />
          </div>
        ) : (
          <TextField
            margin="dense"
            id="name"
            label="Enter Company Name"
            type="text"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        )}
      </DialogContent>
      {!cloning && (
        <DialogActions>
          <Button
            onClick={() => {
              setCloning(false);
              setProgress(0);
              setProgressMessage("");
              setName("");
              setError("");
              handleClose();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            disabled={!name}
            onClick={async () => {
              setCloning(true);
              setError("");
              const result = await cloningFuncs.cloneCompany(
                companyId,
                name,
                setProgress,
                setProgressMessage
              );
              if (result && result.err) {
                setCloning(false);
                setProgress(0);
                setProgressMessage("");
                setName("");
                setError(result.message);
              } else if (result && !result.err && result.message) {
                window.location = `/company/${result.message}`;
              }
            }}
            color="primary"
          >
            Agree
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
