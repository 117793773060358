const lawnbotAPIEndpoint = `${process.env.REACT_APP_DASHBOARD_API}/integration/real-green`;

export const getCompanyEmployees = async (apiKey) => {
  try {
    const employees = await fetch(`${lawnbotAPIEndpoint}/employees`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        Accept: "application/json",
        apiKey: apiKey,
        host: "api.realgreen.com"
      }
    });
    return employees.json();
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getCompanyDiscountCodes = async (apiKey) => {
  try {
    const code = await fetch(`${lawnbotAPIEndpoint}/discounts`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        Accept: "application/json",
        apiKey: apiKey,
        host: "api.realgreen.com"
      }
    });
    return code.json();
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getCompanyRoutes = async (apiKey) => {
  try {
    const routes = await fetch(`${lawnbotAPIEndpoint}/routes`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        Accept: "application/json",
        apiKey: apiKey,
        host: "api.realgreen.com"
      }
    });
    return routes.json();
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getCompanyPrograms = async (apiKey) => {
  try {
    const programs = await fetch(`${lawnbotAPIEndpoint}/programs`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        Accept: "application/json",
        apiKey: apiKey,
        host: "api.realgreen.com"
      }
    });
    return programs.json();
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getCompanyMarketingCodes = async (apiKey) => {
  try {
    const codes = await fetch(`${lawnbotAPIEndpoint}/marketing-sources`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        Accept: "application/json",
        apiKey: apiKey,
        host: "api.realgreen.com"
      }
    });
    return codes.json();
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getCompanyCallStatuses = async (apiKey) => {
  try {
    const codes = await fetch(`${lawnbotAPIEndpoint}/call-statuses`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        Accept: "application/json",
        apiKey: apiKey,
        host: "api.realgreen.com"
      }
    });
    return codes.json();
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getCompanyCallActionReasons = async (apiKey) => {
  try {
    const codes = await fetch(`${lawnbotAPIEndpoint}/call-action-reasons`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        Accept: "application/json",
        apiKey: apiKey,
        host: "api.realgreen.com"
      }
    });
    return codes.json();
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getCompanies = async (apiKey) => {
  try {
    const codes = await fetch(`${lawnbotAPIEndpoint}/companies`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        Accept: "application/json",
        apiKey: apiKey,
        host: "api.realgreen.com"
      }
    });
    return codes.json();
  } catch (e) {
    console.log(e);
    return [];
  }
};
