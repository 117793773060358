import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  makeStyles,
  createStyles,
  Snackbar,
  Breadcrumbs,
  Link,
  Typography
} from "@material-ui/core";

import Title from "../../dashboard/Title";
import { BotInfoForm } from "./bot-info-form";
import { learnMoreTemplate } from "./templates/learn-more.template";
import { objectionsTemplate } from "./templates/objections.template";
import { serviceQuestionTemplate } from "./templates/service-questions.template";
import { checkCompanyAfterFetch } from "../companyUtils";
import {
  createInitialId,
  createInitialVersions
} from "../../../utils/versionUtilFuncs";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap"
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    }
  })
);

export function CompanyBotNew(props) {
  let { companyId } = useParams();
  const classes = useStyles();

  const [company, setCompany] = useState({});
  const [snackOpened, openSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("Bot Saved!  Redirecting!");

  const [botId, setBotId] = useState(0);
  const fetchCompany = useCallback(async () => {
    try {
      const fetchc = await checkCompanyAfterFetch(companyId);
      setCompany(fetchc.data.getCompany);
    } catch (error) {
      console.log("error: ", error.errors[0].message);
    }
  }, [companyId]);

  useEffect(() => {
    // fetch customer record here
    fetchCompany();
  }, [fetchCompany]);

  const submitBot = async (bot) => {
    const payload = {
      ...bot,
      id: createInitialId(companyId),
      botCompanyId: companyId,
      learnMore: learnMoreTemplate(company),
      objections: objectionsTemplate(company),
      serviceQuestions: serviceQuestionTemplate()
    };
    try {
      const fetch = await createInitialVersions("bot", payload);
      setBotId(fetch.id);
      openSnack(true);
    } catch (error) {
      console.log("error: ", error.errors[0].message);
    }
  };
  return (
    <Container className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
        <Link color="inherit" href={`/company/${companyId}`}>
          Company
        </Link>
        <Link color="inherit" href={`/company/${companyId}/bots`}>
          Bots
        </Link>
        <Typography color="textPrimary">New Bot</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <Title>New Bot</Title>
      </div>
      <div className={classes.container}>
        <BotInfoForm submit={submitBot} />
      </div>
      <Snackbar
        open={snackOpened}
        autoHideDuration={3000}
        onClose={() => {
          openSnack(false);
          window.location = `/company/${companyId}/bot/${botId}`;
        }}
        message={snackMessage}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      ></Snackbar>
    </Container>
  );
}
