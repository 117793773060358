import {
  getValueAddHTML
} from "../pages/company/bots/templates/learn-more.template";
import { API, graphqlOperation } from "aws-amplify";

const queries = require("../graphql/queries");
const _ = require("lodash");

function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
export const publishBot = async (company) => {
  const bots = company.bots.items.map((i) => {
    if (i.learnMore && IsJsonString(i.learnMore)) {
      i.learnMore = JSON.parse(i.learnMore);
    }

    if (!i.learnMore) {
      i.learnMore = [
        {
          id: "99999",
          name: "learn-more-insert",
          steps: [],
          probing_questions: []
        }
      ];
    }

    let branding = {};
    if (company.branding) {
      branding = company.branding;
    }

    let probing = [];
    if (i.learnMore) {
      probing = [...i.learnMore[0].probing_questions].filter(
        (z) => z.id !== "learn-more"
      );
    }

    probing.push({
      id: "learn-more",
      component: {
        type: "html",
        html: getValueAddHTML(company.branding)
      },
      asMessage: false,
      trigger: "company-learn-more-description"
    });
    i.learnMore[0].probing_questions = probing;

    i.objections = IsJsonString(i.objections)
      ? JSON.parse(i.objections)
      : i.objections;
    i.serviceQuestions = IsJsonString(i.serviceQuestions)
      ? JSON.parse(i.serviceQuestions)
      : i.serviceQuestions;
    return i;
  });

  await Promise.all(
    company.programs.items.map(async (program) => {
      const payload = {
        id: program.id,
        version: "v0"
      };
      try {
        const getServices = await API.graphql(
          graphqlOperation(queries.getProgramVersioned, payload)
        );
        program.services = getServices.data.getProgramVersioned.services;
      } catch (e) {
        console.log(e);
      }

      return program;
    })
  );

  await Promise.all(
    company.priceTables.items.map(async (priceTable) => {
      const payload = {
        id: priceTable.id,
        version: "v0"
      };
      try {
        const getPricetTable = await API.graphql(
          graphqlOperation(queries.getPriceTableVersioned, payload)
        );
        priceTable.rows = getPricetTable.data.getPriceTableVersioned.rows;
      } catch (e) {
        console.log(e);
      }

      return priceTable;
    })
  );

  company.bots.items = bots;

  /*  Consolidate the probing questions */
  const services = _.uniq(company.programs.items.map((i) => i.programType));

  services.forEach((s) => {
    const pp = company.programs.items.filter((p) => p.programType === s);
    if (pp.length > 1) {
      const selected = pp.find((i) => i.useProbingQuestions);
      if (selected) {
        pp.filter((i) => !i.useProbingQuestions).forEach((t) => {
          t.probingQuestionsJson = selected.probingQuestionsJson;
        });
      }
    }
  });

  try {
    const data = await API.post(
      "bridgeApi",
      `/dashboard/bot/publish`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.parse(JSON.stringify(company, null, 2))
      }
    );
    
    alert('published');
  } catch (e) {
    alert('Failed to publish!');
  }
};
