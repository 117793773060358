import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  makeStyles,
  createStyles,
  Snackbar,
  Breadcrumbs,
  Link,
  Typography
} from "@material-ui/core";

import Title from "../../dashboard/Title";
import { API, graphqlOperation } from "aws-amplify";
import {
  createProgram,
  createProbingQuestions,
  updateProgram
} from "../../../graphql/mutations";
import { ProgramInfoForm } from "./program-info-form";
import {
  createNewVersion,
  createInitialVersions,
  createInitialId
} from "../../../utils/versionUtilFuncs";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap"
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    }
  })
);

export function CompanyProgramNew(props) {
  let { companyId } = useParams();
  const classes = useStyles();

  const [snackOpened, openSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState(
    "Program Saved!  Refreshing"
  );
  const [programId, setProgramId] = useState(0);

  const submitProgram = async (program) => {
    const probingQuestions = {
      sectionName: "probing-questions-" + Math.floor(Math.random() * 10000),
      entryId: "entry-" + Math.floor(Math.random() * 10000),
      nextSectionId: "next-probing-section"
    };
    const id = createInitialId(companyId);
    probingQuestions["probingQuestionsProgramId"] = id;
    delete probingQuestions.__typename;
    
    try {
      const fetch2 = await API.graphql(
        graphqlOperation(createProbingQuestions, { input: probingQuestions })
      );

      const pid = fetch2.data.createProbingQuestions.id;
      const payload = {
        ...program,
        id,
        programProbingQuestionsId: pid,
        programCompanyId: companyId
      };

      await createInitialVersions("program", payload);
      setProgramId(id);
      openSnack(true);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <Container className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
        <Link color="inherit" href={`/company/${companyId}`}>
          Company
        </Link>
        <Link color="inherit" href={`/company/${companyId}/programs`}>
          Programs
        </Link>
        <Typography color="textPrimary">New Program</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <Title>New Program</Title>
      </div>
      <div className={classes.container}>
        <ProgramInfoForm submit={submitProgram} />
      </div>
      <Snackbar
        open={snackOpened}
        autoHideDuration={1500}
        onClose={() => {
          openSnack(false);
          window.location = `/company/${companyId}/program/${programId}`;
        }}
        message={snackMessage}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      ></Snackbar>
    </Container>
  );
}
