import { API, graphqlOperation } from "aws-amplify";
import { getCompanyVersioned } from "./../../graphql/queries";
// if a user ever tries to access a company that doesn't exist, we need to handle that somehow
export async function checkCompanyAfterFetch(companyId, version = "v0") {
  try {
    const fetch = await API.graphql(
      graphqlOperation(getCompanyVersioned, {
        id: companyId,
        version,
        limit: 10000,
        sortDirection: "ASC"
      })
    );
    if (!fetch.data.getCompanyVersioned) {
      window.location.href = "/company";
    } else {
      return {
        data: {
          getCompany: fetch.data.getCompanyVersioned
        }
      };
    }
  } catch (error) {
    console.log("error: ", error.errors[0].message);
  }
}
