import React, { useState, useEffect } from "react";
import {
  MenuItem,
  Button,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box
} from "@material-ui/core";
import { PropTypes } from "prop-types";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from "react-material-ui-form-validator";

import programMonths from "./program-months";

import { API, graphqlOperation } from "aws-amplify";
import { listServiceTypes } from "../../../graphql/queries";
import { RGDiscountRoundList } from "./program-detail";
// import TextFieldWithTooltip from "../../../components/input-fields/TextFieldWithTooltip";

export function ProgramInfoForm(props) {
  const { action = "Save", priceTables } = props;

  const [selectedPriceTable, setSelectedPriceTable] = useState(null);

  const buttonStyle = {
    marginTop: 20
  };

  const submitForm = (e) => {
    // const type = serviceTypes.find((i) => i.name === program.programType);
    // const payload = Object.assign({}, program, {programType: type});
    const payload = program;
    delete program.probingQuestions;

    program.realGreenApplicationDiscountCode = JSON.stringify(
      program.realGreenDiscountTable
    );
    delete program.realGreenDiscountTable;
    props.submit(payload);
  };
  const updateForm = (e) => {
    const key = e.target.id;
    const val = e.target.value;
    const payload = {};
    payload[key] = val;
    setProgram({ ...program, ...payload });
  };


  let localProgram = {};
  if (!props.program) {
    localProgram = {
      programName: "",
      programDescription: "",
      programStartMonth: 1,
      programEndMonth: 12,
      programType: "mowing",
      isUpsell: false,
      active: true,
      alwaysAvailable: false,
      alwaysOnTop: false,
      excludedZips: "",
      useProbingQuestions: false,
      useRealGreenPricingEngine: false,
      realGreenDiscountTable: [],
      usesGoogleSheetPricing: false,
      googleSheetSheetId: "",
      googleSheetTabName: "",
      googleSheetRangeMapping: "",
      googleSheetResultsMapping: "",
      alternativePaymentVerbiage: "installment",

    };
  } else {
    localProgram = props.program;

    if (
      typeof localProgram.realGreenDiscountTable === "undefined" &&
      !localProgram.realGreenApplicationDiscountCode
    ) {
      localProgram.realGreenDiscountTable = [];
    } else {
      try {
        localProgram.realGreenDiscountTable = JSON.parse(
          localProgram.realGreenApplicationDiscountCode
        );
      } catch {
        localProgram.realGreenDiscountTable = [];
      }
    }
  }

  const [program, setProgram] = useState(localProgram);

  const [serviceTypes, setServiceTypes] = useState([]);

  const fetchServiceTypes = async () => {
    try {
      const fetch = await API.graphql(
        graphqlOperation(listServiceTypes, { limit: 1000 })
      );
      setServiceTypes(fetch.data.listServiceTypes.items);
    } catch (error) {
      console.log("error: ", error);
    }
  };


  useEffect(() => {
    fetchServiceTypes();
  }, []);
  return (
    <React.Fragment>
      <ValidatorForm onSubmit={submitForm}>
        <TextValidator
          id="programName"
          label="Program Name"
          value={program.programName}
          style={{ margin: 8, width: 300 }}
          placeholder="Awesome Fert Program"
          margin="normal"
          onChange={updateForm}
          onBlur={updateForm}
          errorMessages={["this field is required"]}
          InputLabelProps={{
            shrink: true
          }}
          validators={["required"]}
        />

        <div>
          <TextValidator
            id="programDescription"
            label="Program Description"
            value={program.programDescription}
            style={{ margin: 8, width: 300 }}
            placeholder="Our Best Program..."
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
            onChange={updateForm}
            onBlur={updateForm}
          />
        </div>

        <div>
          <SelectValidator
            labelid="programType"
            id="programType"
            label="Program Type"
            style={{
              marginTop: 24,
              marginRight: 10,
              marginLeft: 10,
              minWidth: 200
            }}
            value={program.programType}
            onChange={(e) => {
              const payload = Object.assign({}, program, {
                programType: e.target.value
              });
              setProgram(payload);
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
          >
            {serviceTypes.map((i) => {
              const key = "menu-item-" + Math.floor(Math.random() * 100000);
              return (
                <MenuItem key={key} value={i.name}>
                  {i.name}
                </MenuItem>
              );
            })}
          </SelectValidator>
        </div>
        <div>
          <SelectValidator
            labelid="programStartMonth"
            id="programStartMonth"
            label="Start Month"
            style={{
              marginTop: 24,
              marginRight: 10,
              marginLeft: 10,
              minWidth: 200
            }}
            value={program.programStartMonth}
            onChange={(e) => {
              const payload = Object.assign({}, program, {
                programStartMonth: e.target.value
              });
              setProgram(payload);
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
          >
            {programMonths.map((i) => {
              const key = "menu-item-" + Math.floor(Math.random() * 100000);
              return (
                <MenuItem key={key} value={i.value}>
                  {i.name}
                </MenuItem>
              );
            })}
          </SelectValidator>
        </div>
        <div>
          <SelectValidator
            labelid="programEndMonth"
            id="programEndMonth"
            label="End Month"
            style={{
              marginTop: 24,
              marginRight: 10,
              marginLeft: 10,
              minWidth: 200
            }}
            value={program.programEndMonth}
            onChange={(e) => {
              const payload = Object.assign({}, program, {
                programEndMonth: e.target.value
              });
              setProgram(payload);
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
          >
            {programMonths.map((i) => {
              const key = "menu-item-" + Math.floor(Math.random() * 100000);
              return (
                <MenuItem key={key} value={i.value}>
                  {i.name}
                </MenuItem>
              );
            })}
          </SelectValidator>
        </div>

        <div>
          <br />
          <TextValidator
            id="alternativeApplicationString"
            label="Alternative Application Label"
            value={program.alternativeApplicationString || "application"}
            style={{ margin: 8, width: 300 }}
            placeholder="1000"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
            onChange={(e) => {
              const payload = Object.assign({}, program, {
                alternativeApplicationString: e.target.value
              });
              setProgram(payload);
            }}
            onBlur={(e) => {
              const payload = Object.assign({}, program, {
                alternativeApplicationString: e.target.value
              });
              setProgram(payload);
            }}
          />
        </div>

        {/* <Box sx={{ m: 1, mt: 2 }}>
          <TextFieldWithTooltip
            id="alternativePaymentVerbiage"
            label="Alternative Payment Verbiage"
            toolTipDescription="This text will be included in the offering description. For example: $XXX to start, followed by $XX per <<installment>>."
            value={program.alternativePaymentVerbiage || ""}
            onChange={(value) => {
              const payload = Object.assign({}, program, {
                alternativePaymentVerbiage: value
              });
              setProgram(payload);
            }}
          />
        </Box> */}

        {/* new product offering */}
        <div style={{ padding: 15, marginTop: 10 }}>
          <FormLabel component="legend">Product Offering</FormLabel>
          <RadioGroup
            aria-label="seasonal"
            name="seasonal"
            value={
              program.productOffering === null
                ? "dont-roll"
                : program.productOffering
            }
            onChange={(e) => {
              const payload = Object.assign({}, program, {
                productOffering: e.target.value
              });
              setProgram(payload);
            }}
          >
            <FormControlLabel
              value="dont-roll"
              control={<Radio />}
              label="Don't Roll After Complete (Rolls on Dec 1st)"
            />
            <FormControlLabel
              value="roll-if-empty"
              control={<Radio />}
              label="Roll If Services Are Empty (Rolls when services equal to zero)"
            />
          </RadioGroup>
        </div>

        { props.crmSolution === "google-sheets" ? (
          
          <div>
          <div style={{ padding: 15 }}>
            <FormLabel>{"Use Google Sheets Pricing Engine"}</FormLabel>
            <Checkbox
              id="googleSheetPricing"
              label="google sheet"
              checked={program.usesGoogleSheetPricing}
              onChange={(e) => {
                const payload = Object.assign({}, program, {
                  usesGoogleSheetPricing: e.target.checked
                });
                setProgram(payload);
              }}
            />
          </div>
          {program.usesGoogleSheetPricing ? (
            <div>
              <TextValidator
                
                id="googleSheetSheetId"
                label="Google Sheet Id"
                value={program.googleSheetSheetId}
                style={{ margin: 8, width: 300 }}
                placeholder="1J2K3L
                "
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                errorMessages={["this field is required"]}
                validators={["required"]}
                onChange={(e) => {
                  const payload = Object.assign({}, program, {
                    googleSheetSheetId: e.target.value
                  });
                  setProgram(payload);
                }}
                onBlur={(e) => {
                  const payload = Object.assign({}, program, {
                    googleSheetSheetId: e.target.value
                  });
                  setProgram(payload);
                }}
              />

              <TextValidator
                
                id="googleSheetTabName"
                label="Google Tab Name"
                value={program.googleSheetTabName}
                style={{ margin: 8, width: 300 }}
                placeholder="1J2K3L
                "
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                errorMessages={["this field is required"]}
                validators={["required"]}
                onChange={(e) => {
                  const payload = Object.assign({}, program, {
                    googleSheetTabName: e.target.value
                  });
                  setProgram(payload);
                }}
                onBlur={(e) => {
                  const payload = Object.assign({}, program, {
                    googleSheetTabName: e.target.value
                  });
                  setProgram(payload);
                }}
              />
              <p>Google Sheets Variable Mapping</p>
              
              <textarea
                  id="googleSheetRangeMapping"
                  rows={10}
                  cols={50}
                  placeholder="numberOfStories|E12"
                  value={program.googleSheetRangeMapping}
                  onChange={updateForm}
                ></textarea>
              <p>Google Sheets Results Mapping</p>
              
              <textarea
                  id="googleSheetResultsMapping"
                  rows={10}
                  cols={50}
                  placeholder="initialPrice|E12"
                  value={program.googleSheetResultsMapping}
                  onChange={updateForm}
                ></textarea>

              </div>) : null}        
        
        </div>
        ) : null}
        {props.crmSolution === "real-green" ? (
          <div>
            <div style={{ padding: 15 }}>
              <FormLabel>{"Use Real Green's Pricing Engine"}</FormLabel>
              <Checkbox
                id="realtimeengine"
                label="real time engine"
                checked={program.useRealGreenPricingEngine}
                onChange={(e) => {
                  const payload = Object.assign({}, program, {
                    useRealGreenPricingEngine: e.target.checked
                  });
                  setProgram(payload);
                }}
              />
            </div>

            <div>
              <SelectValidator
                labelid="crmProgamCode"
                id="crmProgamCode"
                label="Real Green Program"
                style={{
                  marginTop: 24,
                  marginRight: 10,
                  marginLeft: 10,
                  minWidth: 200
                }}
                value={program.crmProgamCode ? program.crmProgamCode : ""}
                onChange={(e) => {
                  const payload = Object.assign({}, program, {
                    crmProgamCode: e.target.value
                  });
                  setProgram(payload);
                }}
                errorMessages={["this field is required"]}
                validators={["required"]}
              >
                {props.crmPrograms
                  .filter((i) => i.available)
                  .map((i) => {
                    const key =
                      "menu-item-" + Math.floor(Math.random() * 100000);

                    return (
                      <MenuItem key={key} value={i.progdefid + ""}>
                        {i.prgm_desc} ({i.prgm_code})
                      </MenuItem>
                    );
                  })}
              </SelectValidator>
            </div>
          </div>
        ) : null}

        {props.crmSolution === "real-green" &&
        program.useRealGreenPricingEngine &&
        props.crmDiscounts ? (
          <div>
            <SelectValidator
              labelid="realGreenPrepayDiscountCode"
              id="realGreenPrepayDiscountCode"
              label="Prepay Discount Code"
              style={{
                marginTop: 24,
                marginRight: 10,
                marginLeft: 10,
                minWidth: 200
              }}
              value={
                program.realGreenPrepayDiscountCode
                  ? program.realGreenPrepayDiscountCode
                  : ""
              }
              onChange={(e) => {
                const payload = Object.assign({}, program, {
                  realGreenPrepayDiscountCode: e.target.value
                });
                setProgram(payload);
              }}
              errorMessages={["this field is required"]}
              validators={["required"]}
            >
              {props.crmDiscounts
                .filter((i) => i.available)
                .filter((i) => i.discountDescription.trim())
                .filter((i) => i.dollarDiscount === 1)
                .map((i) => {
                  const key = "menu-item-" + Math.floor(Math.random() * 100000);

                  const discountAmount = i.discountAmount;
                  const discountType =
                    i.dollarDiscount === 1
                      ? discountAmount + "% Off"
                      : "$" +
                        discountAmount.toFixed(2) +
                        (i.dollarDiscount === 3 ? " Fixed Price" : " Off");
                  return (
                    <MenuItem key={key} value={i.id + ""}>
                      {i.discountDescription} ({discountType})
                    </MenuItem>
                  );
                })}
            </SelectValidator>
            <h2>Discount Table</h2>
            <RGDiscountRoundList
              rounds={program.realGreenDiscountTable}
              discountCodes={props.crmDiscounts}
              callback={(table) => {
                const nprogram = { ...program, realGreenDiscountTable: table };
                setProgram(nprogram);
              }}
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                const payload = { ...program };

                if (!payload.realGreenDiscountTable) {
                  payload.realGreenDiscountTable = [];
                }

                payload.realGreenDiscountTable.push({
                  round: 0,
                  code: null,
                  isSlidingScale: false,
                  sqftLowerRange: 0,
                  sqftUpperRange: 1000,
                  discountData: {}
                });
                setProgram(payload);
              }}
            >
              add discount round
            </button>
            <div></div>
          </div>
        ) : null}

        <div style={{ padding: 15 }}>
          <FormLabel>Active</FormLabel>
          <Checkbox
            id="active"
            label="Active"
            checked={program.active}
            onChange={(e) => {
              const payload = Object.assign({}, program, {
                active: e.target.checked
              });
              setProgram(payload);
            }}
          />
        </div>

        <div style={{ padding: 15 }}>
          <FormLabel>Hide Discount Text in the Bot</FormLabel>
          <Checkbox
            id="hideDiscountText"
            label="Hide Discount Text"
            checked={
              program.hideDiscountText === undefined
                ? false
                : program.hideDiscountText
            }
            onChange={(e) => {
              const payload = Object.assign({}, program, {
                hideDiscountText: e.target.checked
              });
              setProgram(payload);
            }}
          />
        </div>
        <div style={{ padding: 15 }}>
          <FormLabel>Is Upsell?</FormLabel>
          <Checkbox
            id="isUpsell"
            checked={program.isUpsell}
            onChange={(e) => {
              const payload = Object.assign({}, program, {
                isUpsell: e.target.checked
              });
              setProgram(payload);
            }}
          />
        </div>
        <div style={{ padding: 15 }}>
          <FormLabel>Always On-Top</FormLabel>
          <Checkbox
            id="alwaysOnTop"
            label="Always On Top"
            checked={program.alwaysOnTop}
            onChange={(e) => {
              const payload = Object.assign({}, program, {
                alwaysOnTop: e.target.checked
              });
              setProgram(payload);
            }}
          />
        </div>

        <div style={{ padding: 15 }}>
          <FormLabel>Always Available</FormLabel>
          <Checkbox
            id="alwaysAvailable"
            label="Always Available"
            checked={program.alwaysAvailable}
            onChange={(e) => {
              const payload = Object.assign({}, program, {
                alwaysAvailable: e.target.checked
              });
              setProgram(payload);
            }}
          />
        </div>

        <div style={{ padding: 15 }}>
          <FormLabel>Use Probing Questions</FormLabel>
          <Checkbox
            id="useProbingQuestions"
            label="Use Probing Questions"
            checked={program.useProbingQuestions || false}
            onChange={(e) => {
              const payload = Object.assign({}, program, {
                useProbingQuestions: e.target.checked
              });
              setProgram(payload);
            }}
          />
        </div>

        <div style={{ padding: 15 }}>
          <FormLabel component="legend">Seasonal Text</FormLabel>
          <RadioGroup
            aria-label="seasonal"
            name="seasonal"
            value={
              typeof program.seasonalText === "undefined"
                ? "remaining"
                : program.seasonalText
            }
            onChange={(e) => {
              const payload = Object.assign({}, program, {
                seasonalText: e.target.value
              });
              setProgram(payload);
            }}
          >
            <FormControlLabel
              value="empty"
              control={<Radio />}
              label="(Empty)"
            />
            <FormControlLabel
              value="remaining"
              control={<Radio />}
              label="Remaining"
            />
            <FormControlLabel
              value="year"
              control={<Radio />}
              label="Remaining This Year"
            />
            <FormControlLabel
              value="season"
              control={<Radio />}
              label="Remaining This Season"
            />
          </RadioGroup>
        </div>

        <div style={{ padding: 15 }}>
            <hr />
            <h2>Up Front Pricing</h2>
            <div style={{ padding: 15 }}>
          <FormLabel>Charge Upfront Pricing</FormLabel>
          <Checkbox
            id="usesUpfrontPricing"
            label="ByPass Credit Card Payment"
            checked={program.usesUpfrontPricing || false}
            onChange={(e) => {
              const payload = Object.assign({}, program, {
                usesUpfrontPricing: e.target.checked
              });
              setProgram(payload);
            }}
          />
        </div>
        {program.usesUpfrontPricing ? (<div style={{ padding: 15 }}>
        
          <SelectValidator
            labelid="upfrontPriceTable"
            id="upfrontPriceTable"
            label="Upront Price Table"
            style={{
              marginTop: 24,
              marginRight: 10,
              marginLeft: 10,
              minWidth: 200
            }}
            value={program.upfrontPricingTable}
            onChange={(e) => {
              const payload = Object.assign({}, program, {
                upfrontPricingTable: e.target.value
              });
              setProgram(payload);
            }}
            errorMessages={["this field is required"]}
            validators={[]}
          >
            {priceTables.map((i) => {
              const key = "menu-item-" + Math.floor(Math.random() * 100000);
              return (
                <MenuItem key={key} value={i.id}>
                  {i.name}
                </MenuItem>
              );
            })}
          </SelectValidator>
 
          </div>) : null}

         {program.usesUpfrontPricing ? ( <div style={{ padding: 15 }}>
        
        <SelectValidator
          labelid="recurringPricingTable"
          id="recurringPricingTable"
          label="Recurring Price Table"
          style={{
            marginTop: 24,
            marginRight: 10,
            marginLeft: 10,
            minWidth: 200
          }}
          value={program.recurringPricingTable}
          onChange={(e) => {
            const payload = Object.assign({}, program, {
              recurringPricingTable: e.target.value
            });
            setProgram(payload);
          }}
          errorMessages={["this field is required"]}
          validators={[]}
        >
          {priceTables.map((i) => {
            const key = "menu-item-" + Math.floor(Math.random() * 100000);
            return (
              <MenuItem key={key} value={i.id}>
                {i.name}
              </MenuItem>
            );
          })}
        </SelectValidator>

        </div>) : null }

          </div>

        <div>
          <br />
          <p>Excluded Zip Codes</p>
          <br />
          <br />
          <textarea
            id="excludedZips"
            rows={20}
            value={program.excludedZips}
            onChange={updateForm}
          ></textarea>
        </div>

        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={buttonStyle}
        >
          {action} Program
        </Button>
      </ValidatorForm>
    </React.Fragment>
  );
}

ProgramInfoForm.propTypes = {
  program: PropTypes.object,
  submit: PropTypes.func
};
