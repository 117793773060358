export const preModalTemplate = () => {
  const q = [
    {
      name: "pre-modal-questions",
      type: "questions 2",
      steps: [
        {
          id: "pre-modal-entry",
          message: "What type of grass do you have?",
          trigger: "get-type-of-grass"
        },
        {
          id: "we-dont-service",
          message: "Oh Bummer... We don't Service That kind of grass.",
          trigger: "refer-our-partner"
        },
        {
          id: "refer-our-partner",
          message:
            "We have partners who can help you though.... please leave a message below and we will connect you.",
          trigger: "get-comments"
        },
        {
          id: "great-we-service",
          message: "Fantastic... we service all kinds of good stuff...",
          trigger: "ask-quote-now"
        }
      ],
      probing_questions: [
        {
          id: "get-type-of-grass",
          options: [
            {
              value: "Bermuda",
              label: "Bermuda",
              image:
                "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-bermuda-grass.jpg"
            },
            {
              value: "Zoysia",
              label: "Zoysia",
              image:
                "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-zoysia-grass.jpg"
            },
            {
              value: "Centipede",
              label: "Centipede",
              image:
                "https://lawnbot-assets.s3.us-east-2.amazonaws.com/ClientBermuda.png"
            },
            {
              value: "St. Augustine",
              label: "St. Augustine",
              image:
                "https://lawnbot-assets.s3.us-east-2.amazonaws.com/ClientSt.Augustine.png"
            },
            {
              value: "Fescue",
              label: "Fescue",
              image:
                "https://lawnbot-assets.s3.us-east-2.amazonaws.com/kentucky-bg-fescue.jpg"
            },
            {
              value: "I don't know",
              label: "I don't know",
              image:
                "https://lawnbot-assets.s3.us-east-2.amazonaws.com/QuestionMark.png"
            }
          ],
          conditionals: [
            {
              matches: "Bermuda",
              trigger: "great-we-service"
            },
            {
              matches: "Zoysia",
              trigger: "great-we-service"
            },
            {
              matches: "Centipede",
              trigger: "great-we-service"
            },
            {
              matches: "St. Augustine",
              trigger: "great-we-service"
            },
            {
              matches: "Fescue",
              trigger: "great-we-service"
            },
            {
              matches: "I don't know",
              trigger: "great-we-service"
            }
          ],
          optionType: "image-text-option",
          fillOptions: true,
          setVariable: "whatKindOfGrass"
        },
        {
          id: "ask-notification-before",
          options: [
            {
              value: "Email",
              label: "Email",
              hideText: true
            },
            {
              value: "Text Message",
              label: "Text Message",
              hideText: true
            },
            {
              value: "Both",
              label: "Both",
              hideText: true
            },
            {
              value: "Don't Notify Me",
              label: "Don't Notify Me",
              hideText: true
            }
          ],
          optionType: "default",
          setVariable: "notificationMethod",
          trigger: "well-thank-you"
        }
      ]
    }
  ];

  return JSON.stringify(q, null, 2);
};
