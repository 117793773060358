import React from "react";
import Dashboard from "./pages/dashboard/Dashboard";
import "./App.css";
import "react-image-crop/dist/ReactCrop.css";
import { BrowserRouter as Router } from "react-router-dom";

import { withAuthenticator } from "aws-amplify-react"; // or 'aws-amplify-react-native';

function App() {
  return (
    <Router>
      <Dashboard />
    </Router>
  );
}

export default withAuthenticator(App);
