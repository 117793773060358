import React, { useState } from "react";

import {
  Container,
  makeStyles,
  createStyles,
  Breadcrumbs,
  Link,
  Typography,
  Snackbar
} from "@material-ui/core";

import { PriceTableInfoForm } from "./price-table-info-form";

import { useParams } from "react-router-dom";
import Title from "../../dashboard/Title";
import {
  createNewVersion,
  createInitialVersions,
  createInitialId
} from "../../../utils/versionUtilFuncs";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap"
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    }
  })
);

export function CompanyPriceTableNew(props) {
  const classes = useStyles();
  let { companyId } = useParams();

  const [snackOpened, openSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState(
    "Price Table Saved!  Refreshing"
  );
  const [priceTableId, setPriceTableId] = useState(0);

  const submitPriceTable = async (priceTable) => {
    const payload = Object.assign({}, priceTable, {
      priceTableCompanyId: companyId,
      id: createInitialId(companyId)
    });
    try {
      const fetch = await createInitialVersions("priceTable", payload);
      const id = fetch.id;
      setPriceTableId(id);
      openSnack(true);
    } catch (error) {
      console.log("error: ", error);
    }
  };
  return (
    <Container className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
        <Link color="inherit" href={`/company/${companyId}`}>
          Company
        </Link>
        <Link color="inherit" href={`/company/${companyId}/price-tables`}>
          Price Tables
        </Link>
        <Typography color="textPrimary">New Price Table</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <Title>New Price Table</Title>
      </div>
      <div className={classes.container}>
        <PriceTableInfoForm submit={submitPriceTable} />
      </div>
      <Snackbar
        open={snackOpened}
        autoHideDuration={1500}
        onClose={() => {
          openSnack(false);
          window.location = `/company/${companyId}/price-table/${priceTableId}`;
        }}
        message={snackMessage}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      ></Snackbar>
    </Container>
  );
}
