/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBot = /* GraphQL */ `
  query GetBot($id: ID!) {
    getBot(id: $id) {
      botName
      botQuoteAnalyzingGif
      botSpeed
      botStatus
      botTitle
      botType
      botVersion
      company {
        autoPayAlternativeLabel
        autoPayAvailable
        autoPayMonthlyPricePeriod
        autoPayPriceMethod
        bots {
          nextToken
          __typename
        }
        branding {
          aboutUsAssetType
          aboutUsAssetUrl
          companyAvatar
          companyLogo
          createdAt
          id
          instaQuoteMessage
          quoteModalImage
          tagLine
          updatedAt
          valueAddDescription
          valueAddImage
          __typename
        }
        bypassPayment
        callActionReason
        callStatusCode
        companyName
        createdAt
        crmCompanyId
        crmDayCode
        crmHandheld
        crmNewRecordStatusScode
        crmPortalUrl
        crmQuoteStatusCode
        crmRoute
        crmSaleStatusCode
        crmSolution
        crmLeadSolution
        customerAddress
        customerCity
        customerEmail
        customerName
        customerPhone
        customerState
        customerZip
        dashboardEmailAddress
        dashboardGreetingName
        dashboardUsername
        id
        lawnbotEmployee
        marketingSource
        maxQuotableSize
        minQuotableSize
        paymentType
        prePayAvailable
        priceTables {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        serviceTax
        termsOfService
        updatedAt
        __typename
      }
      createdAt
      dontTaxZipCodes
      greeting
      hideSummary
      id
      isLive
      isMetric
      learnMore
      lookupZipCodeTax
      measurementMode
      objections
      preModal
      redirectUrl
      salutation
      serviceQuestions
      skipProbingQuestions
      sqFtPercentage
      sqftEstimateSource
      updatedAt
      willRedirect
      zillowFallback
      zipCodes
      __typename
    }
  }
`;
export const getBotVersioned = /* GraphQL */ `
  query GetBotVersioned($id: ID!, $version: String!) {
    getBotVersioned(id: $id, version: $version) {
      botCompanyId
      botName
      botQuoteAnalyzingGif
      botSpeed
      botStatus
      botTitle
      botType
      botVersion
      company {
        approved
        approvedBy
        approvedOn
        autoPayAlternativeLabel
        autoPayAvailable
        autoPayMonthlyPricePeriod
        autoPayPriceMethod
        bots {
          nextToken
          __typename
        }
        branding {
          aboutUsAssetType
          aboutUsAssetUrl
          companyAvatar
          companyBrandingCompanyId
          companyLogo
          createdAt
          id
          instaQuoteMessage
          quoteModalImage
          tagLine
          updatedAt
          valueAddDescription
          valueAddImage
          version
          __typename
        }
        bypassPayment
        callActionReason
        callStatusCode
        companyBrandingId
        companyDataServicesId
        companyName
        createdAt
        crmCompanyId
        crmDayCode
        crmHandheld
        crmMeasurementUnit
        crmNewRecordStatusScode
        crmPortalUrl
        crmQuoteStatusCode
        crmRoute
        crmSaleStatusCode
        crmSolution
        crmLeadSolution
        currency
        customerAddress
        customerCity
        customerEmail
        customerName
        customerPhone
        customerState
        customerZip
        dashboardEmailAddress
        dashboardGreetingName
        dashboardUsername
        dataServices {
          airTableBase
          airTableTableName
          companyDataServicesCompanyId
          createdAt
          googleSheetsClientEmail
          googleSheetsPrivateKey
          id
          realGreenAPIKey
          salesCenterConfig
          serviceAssistantId
          servmanApiAuth
          servmanApiKey
          servmanApiUrl
          stripeSecret
          stripeTestSecret
          updatedAt
          zillowAPIKey
          __typename
        }
        emergencyTellMeMore
        gettingStartedText
        id
        lawnbotEmployee
        marketingSource
        maxQuotableSize
        minQuotableSize
        noServiceAgreementContent
        offers {
          nextToken
          __typename
        }
        onboardingJson
        paymentType
        pestPacTenantId
        prePayAvailable
        priceTables {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        serviceAgreementContent
        serviceAgreementTellMeMore
        serviceTax
        taxOverride
        termsOfService
        updatedAt
        version
        __typename
      }
      createdAt
      customSchedulingVerbiage
      dontTaxZipCodes
      forceKey
      greeting
      hideArielAndStreetView
      hideResidentialOrCommercialQuestion
      hideSummary
      id
      isLive
      isMetric
      learnMore
      lookupZipCodeTax
      measurementMode
      objections
      preModal
      redirectUrl
      salutation
      sections {
        items {
          createdAt
          entryId
          id
          nextSectionId
          sectionName
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      serviceQuestions
      skipProbingQuestions
      sqFtPercentage
      sqftEstimateSource
      updatedAt
      version
      willRedirect
      zillowFallback
      zipCodes
      __typename
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      autoPayAlternativeLabel
      autoPayAvailable
      autoPayMonthlyPricePeriod
      autoPayPriceMethod
      bots {
        items {
          botName
          botQuoteAnalyzingGif
          botSpeed
          botStatus
          botTitle
          botType
          botVersion
          createdAt
          dontTaxZipCodes
          greeting
          hideSummary
          id
          isLive
          isMetric
          learnMore
          lookupZipCodeTax
          measurementMode
          objections
          preModal
          redirectUrl
          salutation
          serviceQuestions
          skipProbingQuestions
          sqFtPercentage
          sqftEstimateSource
          updatedAt
          willRedirect
          zillowFallback
          zipCodes
          __typename
        }
        nextToken
        __typename
      }
      branding {
        aboutUsAssetType
        aboutUsAssetUrl
        company {
          autoPayAlternativeLabel
          autoPayAvailable
          autoPayMonthlyPricePeriod
          autoPayPriceMethod
          bypassPayment
          callActionReason
          callStatusCode
          companyName
          createdAt
          crmCompanyId
          crmDayCode
          crmHandheld
          crmNewRecordStatusScode
          crmPortalUrl
          crmQuoteStatusCode
          crmRoute
          crmSaleStatusCode
          crmSolution
          crmLeadSolution
          customerAddress
          customerCity
          customerEmail
          customerName
          customerPhone
          customerState
          customerZip
          dashboardEmailAddress
          dashboardGreetingName
          dashboardUsername
          id
          lawnbotEmployee
          marketingSource
          maxQuotableSize
          minQuotableSize
          paymentType
          prePayAvailable
          serviceTax
          termsOfService
          updatedAt
          __typename
        }
        companyAvatar
        companyLogo
        createdAt
        id
        instaQuoteMessage
        quoteModalImage
        tagLine
        updatedAt
        valueAddDescription
        valueAddImage
        __typename
      }
      bypassPayment
      callActionReason
      callStatusCode
      companyName
      createdAt
      crmCompanyId
      crmDayCode
      crmHandheld
      crmNewRecordStatusScode
      crmPortalUrl
      crmQuoteStatusCode
      crmRoute
      crmSaleStatusCode
      crmSolution
      crmLeadSolution
      customerAddress
      customerCity
      customerEmail
      customerName
      customerPhone
      customerState
      customerZip
      dashboardEmailAddress
      dashboardGreetingName
      dashboardUsername
      id
      lawnbotEmployee
      marketingSource
      maxQuotableSize
      minQuotableSize
      paymentType
      prePayAvailable
      priceTables {
        items {
          conditions
          createdAt
          id
          name
          prePayCutoff
          prePayDiscount
          serviceType
          updatedAt
          year
          __typename
        }
        nextToken
        __typename
      }
      programs {
        items {
          active
          alternativeApplicationString
          alternativePaymentVerbiage
          alwaysAvailable
          alwaysOnTop
          conditions
          createdAt
          crmProgamCode
          excludedZips
          googleSheetRangeMapping
          googleSheetResultsMapping
          googleSheetSheetId
          googleSheetTabName
          id
          isUpsell
          probingQuestionsJson
          productOffering
          programDescription
          programEndMonth
          programName
          programStartMonth
          programType
          realGreenApplicationDiscountCode
          realGreenPrepayDiscountCode
          seasonalText
          updatedAt
          upfrontPricingTable
          useProbingQuestions
          useRealGreenPricingEngine
          usesGoogleSheetPricing
          usesUpfrontPricing
          __typename
        }
        nextToken
        __typename
      }
      serviceTax
      termsOfService
      updatedAt
      __typename
    }
  }
`;
export const getCompanyBranding = /* GraphQL */ `
  query GetCompanyBranding($id: ID!) {
    getCompanyBranding(id: $id) {
      aboutUsAssetType
      aboutUsAssetUrl
      company {
        autoPayAlternativeLabel
        autoPayAvailable
        autoPayMonthlyPricePeriod
        autoPayPriceMethod
        bots {
          nextToken
          __typename
        }
        branding {
          aboutUsAssetType
          aboutUsAssetUrl
          companyAvatar
          companyLogo
          createdAt
          id
          instaQuoteMessage
          quoteModalImage
          tagLine
          updatedAt
          valueAddDescription
          valueAddImage
          __typename
        }
        bypassPayment
        callActionReason
        callStatusCode
        companyName
        createdAt
        crmCompanyId
        crmDayCode
        crmHandheld
        crmNewRecordStatusScode
        crmPortalUrl
        crmQuoteStatusCode
        crmRoute
        crmSaleStatusCode
        crmSolution
        crmLeadSolution
        customerAddress
        customerCity
        customerEmail
        customerName
        customerPhone
        customerState
        customerZip
        dashboardEmailAddress
        dashboardGreetingName
        dashboardUsername
        id
        lawnbotEmployee
        marketingSource
        maxQuotableSize
        minQuotableSize
        paymentType
        prePayAvailable
        priceTables {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        serviceTax
        termsOfService
        updatedAt
        __typename
      }
      companyAvatar
      companyLogo
      createdAt
      id
      instaQuoteMessage
      quoteModalImage
      tagLine
      updatedAt
      valueAddDescription
      valueAddImage
      __typename
    }
  }
`;
export const getCompanyBrandingVersioned = /* GraphQL */ `
  query GetCompanyBrandingVersioned($id: ID!, $version: String!) {
    getCompanyBrandingVersioned(id: $id, version: $version) {
      aboutUsAssetType
      aboutUsAssetUrl
      company {
        approved
        approvedBy
        approvedOn
        autoPayAlternativeLabel
        autoPayAvailable
        autoPayMonthlyPricePeriod
        autoPayPriceMethod
        bots {
          nextToken
          __typename
        }
        branding {
          aboutUsAssetType
          aboutUsAssetUrl
          companyAvatar
          companyBrandingCompanyId
          companyLogo
          createdAt
          id
          instaQuoteMessage
          quoteModalImage
          tagLine
          updatedAt
          valueAddDescription
          valueAddImage
          version
          __typename
        }
        bypassPayment
        callActionReason
        callStatusCode
        companyBrandingId
        companyDataServicesId
        companyName
        createdAt
        crmCompanyId
        crmDayCode
        crmHandheld
        crmMeasurementUnit
        crmNewRecordStatusScode
        crmPortalUrl
        crmQuoteStatusCode
        crmRoute
        crmSaleStatusCode
        crmSolution
        crmLeadSolution
        currency
        customerAddress
        customerCity
        customerEmail
        customerName
        customerPhone
        customerState
        customerZip
        dashboardEmailAddress
        dashboardGreetingName
        dashboardUsername
        dataServices {
          airTableBase
          airTableTableName
          companyDataServicesCompanyId
          createdAt
          googleSheetsClientEmail
          googleSheetsPrivateKey
          id
          realGreenAPIKey
          salesCenterConfig
          serviceAssistantId
          servmanApiAuth
          servmanApiKey
          servmanApiUrl
          stripeSecret
          stripeTestSecret
          updatedAt
          zillowAPIKey
          __typename
        }
        emergencyTellMeMore
        gettingStartedText
        id
        lawnbotEmployee
        marketingSource
        maxQuotableSize
        minQuotableSize
        noServiceAgreementContent
        offers {
          nextToken
          __typename
        }
        onboardingJson
        paymentType
        pestPacTenantId
        prePayAvailable
        priceTables {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        serviceAgreementContent
        serviceAgreementTellMeMore
        serviceTax
        taxOverride
        termsOfService
        updatedAt
        version
        __typename
      }
      companyAvatar
      companyBrandingCompanyId
      companyLogo
      createdAt
      id
      instaQuoteMessage
      quoteModalImage
      tagLine
      updatedAt
      valueAddDescription
      valueAddImage
      version
      __typename
    }
  }
`;
export const getCompanyDataServices = /* GraphQL */ `
  query GetCompanyDataServices($id: ID!) {
    getCompanyDataServices(id: $id) {
      airTableBase
      airTableTableName
      company {
        approved
        approvedBy
        approvedOn
        autoPayAlternativeLabel
        autoPayAvailable
        autoPayMonthlyPricePeriod
        autoPayPriceMethod
        bots {
          nextToken
          __typename
        }
        branding {
          aboutUsAssetType
          aboutUsAssetUrl
          companyAvatar
          companyBrandingCompanyId
          companyLogo
          createdAt
          id
          instaQuoteMessage
          quoteModalImage
          tagLine
          updatedAt
          valueAddDescription
          valueAddImage
          version
          __typename
        }
        bypassPayment
        callActionReason
        callStatusCode
        companyBrandingId
        companyDataServicesId
        companyName
        createdAt
        crmCompanyId
        crmDayCode
        crmHandheld
        crmMeasurementUnit
        crmNewRecordStatusScode
        crmPortalUrl
        crmQuoteStatusCode
        crmRoute
        crmSaleStatusCode
        crmSolution
        crmLeadSolution
        currency
        customerAddress
        customerCity
        customerEmail
        customerName
        customerPhone
        customerState
        customerZip
        dashboardEmailAddress
        dashboardGreetingName
        dashboardUsername
        dataServices {
          airTableBase
          airTableTableName
          companyDataServicesCompanyId
          createdAt
          googleSheetsClientEmail
          googleSheetsPrivateKey
          id
          realGreenAPIKey
          salesCenterConfig
          serviceAssistantId
          servmanApiAuth
          servmanApiKey
          servmanApiUrl
          stripeSecret
          stripeTestSecret
          updatedAt
          zillowAPIKey
          __typename
        }
        emergencyTellMeMore
        gettingStartedText
        id
        lawnbotEmployee
        marketingSource
        maxQuotableSize
        minQuotableSize
        noServiceAgreementContent
        offers {
          nextToken
          __typename
        }
        onboardingJson
        paymentType
        pestPacTenantId
        prePayAvailable
        priceTables {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        serviceAgreementContent
        serviceAgreementTellMeMore
        serviceTax
        taxOverride
        termsOfService
        updatedAt
        version
        __typename
      }
      companyDataServicesCompanyId
      createdAt
      googleSheetsClientEmail
      googleSheetsPrivateKey
      id
      realGreenAPIKey
      salesCenterConfig
      serviceAssistantId
      servmanApiAuth
      servmanApiKey
      servmanApiUrl
      stripeSecret
      stripeTestSecret
      updatedAt
      zillowAPIKey
      __typename
    }
  }
`;
export const getCompanyVersioned = /* GraphQL */ `
  query GetCompanyVersioned($id: ID!, $version: String!) {
    getCompanyVersioned(id: $id, version: $version) {
      approved
      approvedBy
      approvedOn
      autoPayAlternativeLabel
      autoPayAvailable
      autoPayMonthlyPricePeriod
      autoPayPriceMethod
      bots {
        items {
          botCompanyId
          botName
          botQuoteAnalyzingGif
          botSpeed
          botStatus
          botTitle
          botType
          botVersion
          createdAt
          customSchedulingVerbiage
          dontTaxZipCodes
          forceKey
          greeting
          hideArielAndStreetView
          hideResidentialOrCommercialQuestion
          hideSummary
          id
          isLive
          isMetric
          learnMore
          lookupZipCodeTax
          measurementMode
          objections
          preModal
          redirectUrl
          salutation
          serviceQuestions
          skipProbingQuestions
          sqFtPercentage
          sqftEstimateSource
          updatedAt
          version
          willRedirect
          zillowFallback
          zipCodes
          __typename
        }
        nextToken
        __typename
      }
      branding {
        aboutUsAssetType
        aboutUsAssetUrl
        company {
          approved
          approvedBy
          approvedOn
          autoPayAlternativeLabel
          autoPayAvailable
          autoPayMonthlyPricePeriod
          autoPayPriceMethod
          bypassPayment
          callActionReason
          callStatusCode
          companyBrandingId
          companyDataServicesId
          companyName
          createdAt
          crmCompanyId
          crmDayCode
          crmHandheld
          crmMeasurementUnit
          crmNewRecordStatusScode
          crmPortalUrl
          crmQuoteStatusCode
          crmRoute
          crmSaleStatusCode
          crmSolution
          crmLeadSolution
          currency
          customerAddress
          customerCity
          customerEmail
          customerName
          customerPhone
          customerState
          customerZip
          dashboardEmailAddress
          dashboardGreetingName
          dashboardUsername
          emergencyTellMeMore
          gettingStartedText
          id
          lawnbotEmployee
          marketingSource
          maxQuotableSize
          minQuotableSize
          noServiceAgreementContent
          onboardingJson
          paymentType
          pestPacTenantId
          prePayAvailable
          serviceAgreementContent
          serviceAgreementTellMeMore
          serviceTax
          taxOverride
          termsOfService
          updatedAt
          version
          __typename
        }
        companyAvatar
        companyBrandingCompanyId
        companyLogo
        createdAt
        id
        instaQuoteMessage
        quoteModalImage
        tagLine
        updatedAt
        valueAddDescription
        valueAddImage
        version
        __typename
      }
      bypassPayment
      callActionReason
      callStatusCode
      companyBrandingId
      companyDataServicesId
      companyName
      createdAt
      crmCompanyId
      crmDayCode
      crmHandheld
      crmMeasurementUnit
      crmNewRecordStatusScode
      crmPortalUrl
      crmQuoteStatusCode
      crmRoute
      crmSaleStatusCode
      crmSolution
      crmLeadSolution
      currency
      customerAddress
      customerCity
      customerEmail
      customerName
      customerPhone
      customerState
      customerZip
      dashboardEmailAddress
      dashboardGreetingName
      dashboardUsername
      dataServices {
        airTableBase
        airTableTableName
        company {
          approved
          approvedBy
          approvedOn
          autoPayAlternativeLabel
          autoPayAvailable
          autoPayMonthlyPricePeriod
          autoPayPriceMethod
          bypassPayment
          callActionReason
          callStatusCode
          companyBrandingId
          companyDataServicesId
          companyName
          createdAt
          crmCompanyId
          crmDayCode
          crmHandheld
          crmMeasurementUnit
          crmNewRecordStatusScode
          crmPortalUrl
          crmQuoteStatusCode
          crmRoute
          crmSaleStatusCode
          crmSolution
          crmLeadSolution
          currency
          customerAddress
          customerCity
          customerEmail
          customerName
          customerPhone
          customerState
          customerZip
          dashboardEmailAddress
          dashboardGreetingName
          dashboardUsername
          emergencyTellMeMore
          gettingStartedText
          id
          lawnbotEmployee
          marketingSource
          maxQuotableSize
          minQuotableSize
          noServiceAgreementContent
          onboardingJson
          paymentType
          pestPacTenantId
          prePayAvailable
          serviceAgreementContent
          serviceAgreementTellMeMore
          serviceTax
          taxOverride
          termsOfService
          updatedAt
          version
          __typename
        }
        companyDataServicesCompanyId
        createdAt
        googleSheetsClientEmail
        googleSheetsPrivateKey
        id
        realGreenAPIKey
        salesCenterConfig
        serviceAssistantId
        servmanApiAuth
        servmanApiKey
        servmanApiUrl
        stripeSecret
        stripeTestSecret
        updatedAt
        zillowAPIKey
        __typename
      }
      emergencyTellMeMore
      gettingStartedText
      id
      lawnbotEmployee
      marketingSource
      maxQuotableSize
      minQuotableSize
      noServiceAgreementContent
      offers {
        items {
          active
          createdAt
          endDate
          hide
          id
          name
          offerCompanyId
          programs
          startDate
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      onboardingJson
      paymentType
      pestPacTenantId
      prePayAvailable
      priceTables {
        items {
          conditions
          createdAt
          id
          isDeleted
          name
          prePayCutoff
          prePayDiscount
          priceTableCompanyId
          serviceType
          updatedAt
          version
          year
          __typename
        }
        nextToken
        __typename
      }
      programs {
        items {
          active
          alternativeApplicationString
          alternativePaymentVerbiage
          alwaysAvailable
          alwaysOnTop
          conditions
          createdAt
          crmProgamCode
          excludedZips
          googleSheetRangeMapping
          googleSheetResultsMapping
          googleSheetSheetId
          googleSheetTabName
          hideDiscountText
          id
          isDeleted
          isUpsell
          probingQuestionsJson
          productOffering
          programCompanyId
          programDescription
          programEndMonth
          programName
          programProbingQuestionsId
          programStartMonth
          programType
          realGreenApplicationDiscountCode
          realGreenPrepayDiscountCode
          recurringPricingTable
          seasonalText
          updatedAt
          upfrontPricingTable
          useProbingQuestions
          useRealGreenPricingEngine
          usesGoogleSheetPricing
          usesUpfrontPricing
          version
          __typename
        }
        nextToken
        __typename
      }
      serviceAgreementContent
      serviceAgreementTellMeMore
      serviceTax
      taxOverride
      termsOfService
      updatedAt
      version
      __typename
    }
  }
`;
export const getComponent = /* GraphQL */ `
  query GetComponent($id: ID!) {
    getComponent(id: $id) {
      componentName
      createdAt
      id
      props {
        items {
          createdAt
          id
          key
          updatedAt
          value
          __typename
        }
        nextToken
        __typename
      }
      step {
        asMessage
        component {
          componentName
          createdAt
          id
          updatedAt
          __typename
        }
        createdAt
        end
        id
        message
        optionType
        options {
          nextToken
          __typename
        }
        section {
          createdAt
          entryId
          id
          nextSectionId
          sectionName
          updatedAt
          __typename
        }
        stepName
        trigger
        updatedAt
        waitAction
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const getConditional = /* GraphQL */ `
  query GetConditional($id: ID!) {
    getConditional(id: $id) {
      createdAt
      id
      matches
      section {
        bot {
          botCompanyId
          botName
          botQuoteAnalyzingGif
          botSpeed
          botStatus
          botTitle
          botType
          botVersion
          createdAt
          customSchedulingVerbiage
          dontTaxZipCodes
          forceKey
          greeting
          hideArielAndStreetView
          hideResidentialOrCommercialQuestion
          hideSummary
          id
          isLive
          isMetric
          learnMore
          lookupZipCodeTax
          measurementMode
          objections
          preModal
          redirectUrl
          salutation
          serviceQuestions
          skipProbingQuestions
          sqFtPercentage
          sqftEstimateSource
          updatedAt
          version
          willRedirect
          zillowFallback
          zipCodes
          __typename
        }
        conditionals {
          nextToken
          __typename
        }
        createdAt
        entryId
        id
        nextSectionId
        sectionName
        steps {
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      trigger
      updatedAt
      __typename
    }
  }
`;
export const getOffer = /* GraphQL */ `
  query GetOffer($id: ID!) {
    getOffer(id: $id) {
      active
      company {
        approved
        approvedBy
        approvedOn
        autoPayAlternativeLabel
        autoPayAvailable
        autoPayMonthlyPricePeriod
        autoPayPriceMethod
        bots {
          nextToken
          __typename
        }
        branding {
          aboutUsAssetType
          aboutUsAssetUrl
          companyAvatar
          companyBrandingCompanyId
          companyLogo
          createdAt
          id
          instaQuoteMessage
          quoteModalImage
          tagLine
          updatedAt
          valueAddDescription
          valueAddImage
          version
          __typename
        }
        bypassPayment
        callActionReason
        callStatusCode
        companyBrandingId
        companyDataServicesId
        companyName
        createdAt
        crmCompanyId
        crmDayCode
        crmHandheld
        crmMeasurementUnit
        crmNewRecordStatusScode
        crmPortalUrl
        crmQuoteStatusCode
        crmRoute
        crmSaleStatusCode
        crmSolution
        crmLeadSolution
        currency
        customerAddress
        customerCity
        customerEmail
        customerName
        customerPhone
        customerState
        customerZip
        dashboardEmailAddress
        dashboardGreetingName
        dashboardUsername
        dataServices {
          airTableBase
          airTableTableName
          companyDataServicesCompanyId
          createdAt
          googleSheetsClientEmail
          googleSheetsPrivateKey
          id
          realGreenAPIKey
          salesCenterConfig
          serviceAssistantId
          servmanApiAuth
          servmanApiKey
          servmanApiUrl
          stripeSecret
          stripeTestSecret
          updatedAt
          zillowAPIKey
          __typename
        }
        emergencyTellMeMore
        gettingStartedText
        id
        lawnbotEmployee
        marketingSource
        maxQuotableSize
        minQuotableSize
        noServiceAgreementContent
        offers {
          nextToken
          __typename
        }
        onboardingJson
        paymentType
        pestPacTenantId
        prePayAvailable
        priceTables {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        serviceAgreementContent
        serviceAgreementTellMeMore
        serviceTax
        taxOverride
        termsOfService
        updatedAt
        version
        __typename
      }
      createdAt
      endDate
      hide
      id
      name
      offerCompanyId
      programs
      startDate
      updatedAt
      __typename
    }
  }
`;
export const getOption = /* GraphQL */ `
  query GetOption($id: ID!) {
    getOption(id: $id) {
      color
      createdAt
      iconClass
      id
      image
      label
      step {
        asMessage
        component {
          componentName
          createdAt
          id
          updatedAt
          __typename
        }
        createdAt
        end
        id
        message
        optionType
        options {
          nextToken
          __typename
        }
        section {
          createdAt
          entryId
          id
          nextSectionId
          sectionName
          updatedAt
          __typename
        }
        stepName
        trigger
        updatedAt
        waitAction
        __typename
      }
      updatedAt
      value
      __typename
    }
  }
`;
export const getPriceTable = /* GraphQL */ `
  query GetPriceTable($id: ID!) {
    getPriceTable(id: $id) {
      company {
        autoPayAlternativeLabel
        autoPayAvailable
        autoPayMonthlyPricePeriod
        autoPayPriceMethod
        bots {
          nextToken
          __typename
        }
        branding {
          aboutUsAssetType
          aboutUsAssetUrl
          companyAvatar
          companyLogo
          createdAt
          id
          instaQuoteMessage
          quoteModalImage
          tagLine
          updatedAt
          valueAddDescription
          valueAddImage
          __typename
        }
        bypassPayment
        callActionReason
        callStatusCode
        companyName
        createdAt
        crmCompanyId
        crmDayCode
        crmHandheld
        crmNewRecordStatusScode
        crmPortalUrl
        crmQuoteStatusCode
        crmRoute
        crmSaleStatusCode
        crmSolution
        crmLeadSolution
        customerAddress
        customerCity
        customerEmail
        customerName
        customerPhone
        customerState
        customerZip
        dashboardEmailAddress
        dashboardGreetingName
        dashboardUsername
        id
        lawnbotEmployee
        marketingSource
        maxQuotableSize
        minQuotableSize
        paymentType
        prePayAvailable
        priceTables {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        serviceTax
        termsOfService
        updatedAt
        __typename
      }
      conditions
      createdAt
      id
      name
      prePayCutoff
      prePayDiscount
      serviceType
      updatedAt
      year
      __typename
    }
  }
`;
export const getPriceTableRows = /* GraphQL */ `
  query GetPriceTableRows($id: ID!) {
    getPriceTableRows(id: $id) {
      createdAt
      end
      id
      price
      priceTable {
        company {
          autoPayAlternativeLabel
          autoPayAvailable
          autoPayMonthlyPricePeriod
          autoPayPriceMethod
          bypassPayment
          callActionReason
          callStatusCode
          companyName
          createdAt
          crmCompanyId
          crmDayCode
          crmHandheld
          crmNewRecordStatusScode
          crmPortalUrl
          crmQuoteStatusCode
          crmRoute
          crmSaleStatusCode
          crmSolution
          crmLeadSolution
          customerAddress
          customerCity
          customerEmail
          customerName
          customerPhone
          customerState
          customerZip
          dashboardEmailAddress
          dashboardGreetingName
          dashboardUsername
          id
          lawnbotEmployee
          marketingSource
          maxQuotableSize
          minQuotableSize
          paymentType
          prePayAvailable
          serviceTax
          termsOfService
          updatedAt
          __typename
        }
        conditions
        createdAt
        id
        isDeleted
        name
        prePayCutoff
        prePayDiscount
        priceTableCompanyId
        rows {
          nextToken
          __typename
        }
        serviceType
        updatedAt
        version
        year
        __typename
      }
      priceTableRowsPriceTableId
      start
      units
      updatedAt
      __typename
    }
  }
`;
export const getPriceTableVersioned = /* GraphQL */ `
  query GetPriceTableVersioned($id: ID!, $version: String!) {
    getPriceTableVersioned(id: $id, version: $version) {
      company {
        autoPayAlternativeLabel
        autoPayAvailable
        autoPayMonthlyPricePeriod
        autoPayPriceMethod
        bots {
          nextToken
          __typename
        }
        branding {
          aboutUsAssetType
          aboutUsAssetUrl
          companyAvatar
          companyLogo
          createdAt
          id
          instaQuoteMessage
          quoteModalImage
          tagLine
          updatedAt
          valueAddDescription
          valueAddImage
          __typename
        }
        bypassPayment
        callActionReason
        callStatusCode
        companyName
        createdAt
        crmCompanyId
        crmDayCode
        crmHandheld
        crmNewRecordStatusScode
        crmPortalUrl
        crmQuoteStatusCode
        crmRoute
        crmSaleStatusCode
        crmSolution
        crmLeadSolution
        customerAddress
        customerCity
        customerEmail
        customerName
        customerPhone
        customerState
        customerZip
        dashboardEmailAddress
        dashboardGreetingName
        dashboardUsername
        id
        lawnbotEmployee
        marketingSource
        maxQuotableSize
        minQuotableSize
        paymentType
        prePayAvailable
        priceTables {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        serviceTax
        termsOfService
        updatedAt
        __typename
      }
      conditions
      createdAt
      id
      isDeleted
      name
      prePayCutoff
      prePayDiscount
      priceTableCompanyId
      rows {
        items {
          createdAt
          end
          id
          price
          priceTableRowsPriceTableId
          start
          units
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      serviceType
      updatedAt
      version
      year
      __typename
    }
  }
`;
export const getProbingQuestions = /* GraphQL */ `
  query GetProbingQuestions($id: ID!) {
    getProbingQuestions(id: $id) {
      conditionals {
        createdAt
        id
        matches
        section {
          createdAt
          entryId
          id
          nextSectionId
          sectionName
          updatedAt
          __typename
        }
        trigger
        updatedAt
        __typename
      }
      createdAt
      entryId
      id
      nextSectionId
      probingQuestionsProgramId
      program {
        active
        alternativeApplicationString
        alternativePaymentVerbiage
        alwaysAvailable
        alwaysOnTop
        company {
          approved
          approvedBy
          approvedOn
          autoPayAlternativeLabel
          autoPayAvailable
          autoPayMonthlyPricePeriod
          autoPayPriceMethod
          bypassPayment
          callActionReason
          callStatusCode
          companyBrandingId
          companyDataServicesId
          companyName
          createdAt
          crmCompanyId
          crmDayCode
          crmHandheld
          crmMeasurementUnit
          crmNewRecordStatusScode
          crmPortalUrl
          crmQuoteStatusCode
          crmRoute
          crmSaleStatusCode
          crmSolution
          crmLeadSolution
          currency
          customerAddress
          customerCity
          customerEmail
          customerName
          customerPhone
          customerState
          customerZip
          dashboardEmailAddress
          dashboardGreetingName
          dashboardUsername
          emergencyTellMeMore
          gettingStartedText
          id
          lawnbotEmployee
          marketingSource
          maxQuotableSize
          minQuotableSize
          noServiceAgreementContent
          onboardingJson
          paymentType
          pestPacTenantId
          prePayAvailable
          serviceAgreementContent
          serviceAgreementTellMeMore
          serviceTax
          taxOverride
          termsOfService
          updatedAt
          version
          __typename
        }
        conditions
        createdAt
        crmProgamCode
        excludedZips
        googleSheetRangeMapping
        googleSheetResultsMapping
        googleSheetSheetId
        googleSheetTabName
        hideDiscountText
        id
        isDeleted
        isUpsell
        probingQuestions {
          createdAt
          entryId
          id
          nextSectionId
          probingQuestionsProgramId
          sectionName
          updatedAt
          __typename
        }
        probingQuestionsJson
        productOffering
        programCompanyId
        programDescription
        programEndMonth
        programName
        programProbingQuestionsId
        programStartMonth
        programType
        realGreenApplicationDiscountCode
        realGreenPrepayDiscountCode
        recurringPricingTable
        seasonalText
        services {
          nextToken
          __typename
        }
        updatedAt
        upfrontPricingTable
        useProbingQuestions
        useRealGreenPricingEngine
        usesGoogleSheetPricing
        usesUpfrontPricing
        version
        __typename
      }
      sectionName
      steps {
        asMessage
        component {
          componentName
          createdAt
          id
          updatedAt
          __typename
        }
        createdAt
        end
        id
        message
        optionType
        options {
          nextToken
          __typename
        }
        section {
          createdAt
          entryId
          id
          nextSectionId
          sectionName
          updatedAt
          __typename
        }
        stepName
        trigger
        updatedAt
        waitAction
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const getProgram = /* GraphQL */ `
  query GetProgram($id: ID!) {
    getProgram(id: $id) {
      active
      alternativeApplicationString
      alternativePaymentVerbiage
      alwaysAvailable
      alwaysOnTop
      company {
        autoPayAlternativeLabel
        autoPayAvailable
        autoPayMonthlyPricePeriod
        autoPayPriceMethod
        bots {
          nextToken
          __typename
        }
        branding {
          aboutUsAssetType
          aboutUsAssetUrl
          companyAvatar
          companyLogo
          createdAt
          id
          instaQuoteMessage
          quoteModalImage
          tagLine
          updatedAt
          valueAddDescription
          valueAddImage
          __typename
        }
        bypassPayment
        callActionReason
        callStatusCode
        companyName
        createdAt
        crmCompanyId
        crmDayCode
        crmHandheld
        crmNewRecordStatusScode
        crmPortalUrl
        crmQuoteStatusCode
        crmRoute
        crmSaleStatusCode
        crmSolution
        crmLeadSolution
        customerAddress
        customerCity
        customerEmail
        customerName
        customerPhone
        customerState
        customerZip
        dashboardEmailAddress
        dashboardGreetingName
        dashboardUsername
        id
        lawnbotEmployee
        marketingSource
        maxQuotableSize
        minQuotableSize
        paymentType
        prePayAvailable
        priceTables {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        serviceTax
        termsOfService
        updatedAt
        __typename
      }
      conditions
      createdAt
      crmProgamCode
      excludedZips
      googleSheetRangeMapping
      googleSheetResultsMapping
      googleSheetSheetId
      googleSheetTabName
      id
      isUpsell
      probingQuestionsJson
      productOffering
      programDescription
      programEndMonth
      programName
      programStartMonth
      programType
      realGreenApplicationDiscountCode
      realGreenPrepayDiscountCode
      seasonalText
      services {
        items {
          createdAt
          cutOffDate
          id
          priceTableId
          roundNumber
          serviceName
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      upfrontPricingTable
      useProbingQuestions
      useRealGreenPricingEngine
      usesGoogleSheetPricing
      usesUpfrontPricing
      __typename
    }
  }
`;
export const getProgramVersioned = /* GraphQL */ `
  query GetProgramVersioned($id: ID!, $version: String!) {
    getProgramVersioned(id: $id, version: $version) {
      active
      alternativeApplicationString
      alternativePaymentVerbiage
      alwaysAvailable
      alwaysOnTop
      company {
        approved
        approvedBy
        approvedOn
        autoPayAlternativeLabel
        autoPayAvailable
        autoPayMonthlyPricePeriod
        autoPayPriceMethod
        bots {
          nextToken
          __typename
        }
        branding {
          aboutUsAssetType
          aboutUsAssetUrl
          companyAvatar
          companyBrandingCompanyId
          companyLogo
          createdAt
          id
          instaQuoteMessage
          quoteModalImage
          tagLine
          updatedAt
          valueAddDescription
          valueAddImage
          version
          __typename
        }
        bypassPayment
        callActionReason
        callStatusCode
        companyBrandingId
        companyDataServicesId
        companyName
        createdAt
        crmCompanyId
        crmDayCode
        crmHandheld
        crmMeasurementUnit
        crmNewRecordStatusScode
        crmPortalUrl
        crmQuoteStatusCode
        crmRoute
        crmSaleStatusCode
        crmSolution
        crmLeadSolution
        currency
        customerAddress
        customerCity
        customerEmail
        customerName
        customerPhone
        customerState
        customerZip
        dashboardEmailAddress
        dashboardGreetingName
        dashboardUsername
        dataServices {
          airTableBase
          airTableTableName
          companyDataServicesCompanyId
          createdAt
          googleSheetsClientEmail
          googleSheetsPrivateKey
          id
          realGreenAPIKey
          salesCenterConfig
          serviceAssistantId
          servmanApiAuth
          servmanApiKey
          servmanApiUrl
          stripeSecret
          stripeTestSecret
          updatedAt
          zillowAPIKey
          __typename
        }
        emergencyTellMeMore
        gettingStartedText
        id
        lawnbotEmployee
        marketingSource
        maxQuotableSize
        minQuotableSize
        noServiceAgreementContent
        offers {
          nextToken
          __typename
        }
        onboardingJson
        paymentType
        pestPacTenantId
        prePayAvailable
        priceTables {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        serviceAgreementContent
        serviceAgreementTellMeMore
        serviceTax
        taxOverride
        termsOfService
        updatedAt
        version
        __typename
      }
      conditions
      createdAt
      crmProgamCode
      excludedZips
      googleSheetRangeMapping
      googleSheetResultsMapping
      googleSheetSheetId
      googleSheetTabName
      hideDiscountText
      id
      isDeleted
      isUpsell
      probingQuestions {
        conditionals {
          createdAt
          id
          matches
          trigger
          updatedAt
          __typename
        }
        createdAt
        entryId
        id
        nextSectionId
        probingQuestionsProgramId
        program {
          active
          alternativeApplicationString
          alternativePaymentVerbiage
          alwaysAvailable
          alwaysOnTop
          conditions
          createdAt
          crmProgamCode
          excludedZips
          googleSheetRangeMapping
          googleSheetResultsMapping
          googleSheetSheetId
          googleSheetTabName
          hideDiscountText
          id
          isDeleted
          isUpsell
          probingQuestionsJson
          productOffering
          programCompanyId
          programDescription
          programEndMonth
          programName
          programProbingQuestionsId
          programStartMonth
          programType
          realGreenApplicationDiscountCode
          realGreenPrepayDiscountCode
          recurringPricingTable
          seasonalText
          updatedAt
          upfrontPricingTable
          useProbingQuestions
          useRealGreenPricingEngine
          usesGoogleSheetPricing
          usesUpfrontPricing
          version
          __typename
        }
        sectionName
        steps {
          asMessage
          createdAt
          end
          id
          message
          optionType
          stepName
          trigger
          updatedAt
          waitAction
          __typename
        }
        updatedAt
        __typename
      }
      probingQuestionsJson
      productOffering
      programCompanyId
      programDescription
      programEndMonth
      programName
      programProbingQuestionsId
      programStartMonth
      programType
      realGreenApplicationDiscountCode
      realGreenPrepayDiscountCode
      recurringPricingTable
      seasonalText
      services {
        items {
          createdAt
          cutOffDate
          id
          isDeleted
          priceTableId
          roundNumber
          serviceName
          serviceProgramId
          updatedAt
          version
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      upfrontPricingTable
      useProbingQuestions
      useRealGreenPricingEngine
      usesGoogleSheetPricing
      usesUpfrontPricing
      version
      __typename
    }
  }
`;
export const getProp = /* GraphQL */ `
  query GetProp($id: ID!) {
    getProp(id: $id) {
      component {
        componentName
        createdAt
        id
        props {
          nextToken
          __typename
        }
        step {
          asMessage
          createdAt
          end
          id
          message
          optionType
          stepName
          trigger
          updatedAt
          waitAction
          __typename
        }
        updatedAt
        __typename
      }
      createdAt
      id
      key
      updatedAt
      value
      __typename
    }
  }
`;
export const getSection = /* GraphQL */ `
  query GetSection($id: ID!) {
    getSection(id: $id) {
      bot {
        botCompanyId
        botName
        botQuoteAnalyzingGif
        botSpeed
        botStatus
        botTitle
        botType
        botVersion
        company {
          approved
          approvedBy
          approvedOn
          autoPayAlternativeLabel
          autoPayAvailable
          autoPayMonthlyPricePeriod
          autoPayPriceMethod
          bypassPayment
          callActionReason
          callStatusCode
          companyBrandingId
          companyDataServicesId
          companyName
          createdAt
          crmCompanyId
          crmDayCode
          crmHandheld
          crmMeasurementUnit
          crmNewRecordStatusScode
          crmPortalUrl
          crmQuoteStatusCode
          crmRoute
          crmSaleStatusCode
          crmSolution
          crmLeadSolution
          currency
          customerAddress
          customerCity
          customerEmail
          customerName
          customerPhone
          customerState
          customerZip
          dashboardEmailAddress
          dashboardGreetingName
          dashboardUsername
          emergencyTellMeMore
          gettingStartedText
          id
          lawnbotEmployee
          marketingSource
          maxQuotableSize
          minQuotableSize
          noServiceAgreementContent
          onboardingJson
          paymentType
          pestPacTenantId
          prePayAvailable
          serviceAgreementContent
          serviceAgreementTellMeMore
          serviceTax
          taxOverride
          termsOfService
          updatedAt
          version
          __typename
        }
        createdAt
        customSchedulingVerbiage
        dontTaxZipCodes
        forceKey
        greeting
        hideArielAndStreetView
        hideResidentialOrCommercialQuestion
        hideSummary
        id
        isLive
        isMetric
        learnMore
        lookupZipCodeTax
        measurementMode
        objections
        preModal
        redirectUrl
        salutation
        sections {
          nextToken
          __typename
        }
        serviceQuestions
        skipProbingQuestions
        sqFtPercentage
        sqftEstimateSource
        updatedAt
        version
        willRedirect
        zillowFallback
        zipCodes
        __typename
      }
      conditionals {
        items {
          createdAt
          id
          matches
          trigger
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      entryId
      id
      nextSectionId
      sectionName
      steps {
        items {
          asMessage
          createdAt
          end
          id
          message
          optionType
          stepName
          trigger
          updatedAt
          waitAction
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($id: ID!) {
    getService(id: $id) {
      createdAt
      cutOffDate
      id
      priceTableId
      program {
        active
        alternativeApplicationString
        alternativePaymentVerbiage
        alwaysAvailable
        alwaysOnTop
        company {
          autoPayAlternativeLabel
          autoPayAvailable
          autoPayMonthlyPricePeriod
          autoPayPriceMethod
          bypassPayment
          callActionReason
          callStatusCode
          companyName
          createdAt
          crmCompanyId
          crmDayCode
          crmHandheld
          crmNewRecordStatusScode
          crmPortalUrl
          crmQuoteStatusCode
          crmRoute
          crmSaleStatusCode
          crmSolution
          crmLeadSolution
          customerAddress
          customerCity
          customerEmail
          customerName
          customerPhone
          customerState
          customerZip
          dashboardEmailAddress
          dashboardGreetingName
          dashboardUsername
          id
          lawnbotEmployee
          marketingSource
          maxQuotableSize
          minQuotableSize
          paymentType
          prePayAvailable
          serviceTax
          termsOfService
          updatedAt
          __typename
        }
        conditions
        createdAt
        crmProgamCode
        excludedZips
        googleSheetRangeMapping
        googleSheetResultsMapping
        googleSheetSheetId
        googleSheetTabName
        id
        isUpsell
        probingQuestionsJson
        productOffering
        programDescription
        programEndMonth
        programName
        programStartMonth
        programType
        realGreenApplicationDiscountCode
        realGreenPrepayDiscountCode
        seasonalText
        services {
          nextToken
          __typename
        }
        updatedAt
        upfrontPricingTable
        useProbingQuestions
        useRealGreenPricingEngine
        usesGoogleSheetPricing
        usesUpfrontPricing
        __typename
      }
      roundNumber
      serviceName
      updatedAt
      __typename
    }
  }
`;
export const getServiceType = /* GraphQL */ `
  query GetServiceType($id: ID!) {
    getServiceType(id: $id) {
      createdAt
      id
      name
      updatedAt
      __typename
    }
  }
`;
export const getServiceVersioned = /* GraphQL */ `
  query GetServiceVersioned($id: ID!, $version: String!) {
    getServiceVersioned(id: $id, version: $version) {
      createdAt
      cutOffDate
      id
      isDeleted
      priceTableId
      program {
        active
        alternativeApplicationString
        alternativePaymentVerbiage
        alwaysAvailable
        alwaysOnTop
        company {
          approved
          approvedBy
          approvedOn
          autoPayAlternativeLabel
          autoPayAvailable
          autoPayMonthlyPricePeriod
          autoPayPriceMethod
          bypassPayment
          callActionReason
          callStatusCode
          companyBrandingId
          companyDataServicesId
          companyName
          createdAt
          crmCompanyId
          crmDayCode
          crmHandheld
          crmMeasurementUnit
          crmNewRecordStatusScode
          crmPortalUrl
          crmQuoteStatusCode
          crmRoute
          crmSaleStatusCode
          crmSolution
          crmLeadSolution
          currency
          customerAddress
          customerCity
          customerEmail
          customerName
          customerPhone
          customerState
          customerZip
          dashboardEmailAddress
          dashboardGreetingName
          dashboardUsername
          emergencyTellMeMore
          gettingStartedText
          id
          lawnbotEmployee
          marketingSource
          maxQuotableSize
          minQuotableSize
          noServiceAgreementContent
          onboardingJson
          paymentType
          pestPacTenantId
          prePayAvailable
          serviceAgreementContent
          serviceAgreementTellMeMore
          serviceTax
          taxOverride
          termsOfService
          updatedAt
          version
          __typename
        }
        conditions
        createdAt
        crmProgamCode
        excludedZips
        googleSheetRangeMapping
        googleSheetResultsMapping
        googleSheetSheetId
        googleSheetTabName
        hideDiscountText
        id
        isDeleted
        isUpsell
        probingQuestions {
          createdAt
          entryId
          id
          nextSectionId
          probingQuestionsProgramId
          sectionName
          updatedAt
          __typename
        }
        probingQuestionsJson
        productOffering
        programCompanyId
        programDescription
        programEndMonth
        programName
        programProbingQuestionsId
        programStartMonth
        programType
        realGreenApplicationDiscountCode
        realGreenPrepayDiscountCode
        recurringPricingTable
        seasonalText
        services {
          nextToken
          __typename
        }
        updatedAt
        upfrontPricingTable
        useProbingQuestions
        useRealGreenPricingEngine
        usesGoogleSheetPricing
        usesUpfrontPricing
        version
        __typename
      }
      roundNumber
      serviceName
      serviceProgramId
      updatedAt
      version
      __typename
    }
  }
`;
export const getStep = /* GraphQL */ `
  query GetStep($id: ID!) {
    getStep(id: $id) {
      asMessage
      component {
        componentName
        createdAt
        id
        props {
          nextToken
          __typename
        }
        step {
          asMessage
          createdAt
          end
          id
          message
          optionType
          stepName
          trigger
          updatedAt
          waitAction
          __typename
        }
        updatedAt
        __typename
      }
      createdAt
      end
      id
      message
      optionType
      options {
        items {
          color
          createdAt
          iconClass
          id
          image
          label
          updatedAt
          value
          __typename
        }
        nextToken
        __typename
      }
      section {
        bot {
          botCompanyId
          botName
          botQuoteAnalyzingGif
          botSpeed
          botStatus
          botTitle
          botType
          botVersion
          createdAt
          customSchedulingVerbiage
          dontTaxZipCodes
          forceKey
          greeting
          hideArielAndStreetView
          hideResidentialOrCommercialQuestion
          hideSummary
          id
          isLive
          isMetric
          learnMore
          lookupZipCodeTax
          measurementMode
          objections
          preModal
          redirectUrl
          salutation
          serviceQuestions
          skipProbingQuestions
          sqFtPercentage
          sqftEstimateSource
          updatedAt
          version
          willRedirect
          zillowFallback
          zipCodes
          __typename
        }
        conditionals {
          nextToken
          __typename
        }
        createdAt
        entryId
        id
        nextSectionId
        sectionName
        steps {
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      stepName
      trigger
      updatedAt
      waitAction
      __typename
    }
  }
`;
export const listBotVersioneds = /* GraphQL */ `
  query ListBotVersioneds(
    $filter: ModelBotVersionedFilterInput
    $id: ID
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $version: ModelStringKeyConditionInput
  ) {
    listBotVersioneds(
      filter: $filter
      id: $id
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      version: $version
    ) {
      items {
        botCompanyId
        botName
        botQuoteAnalyzingGif
        botSpeed
        botStatus
        botTitle
        botType
        botVersion
        company {
          approved
          approvedBy
          approvedOn
          autoPayAlternativeLabel
          autoPayAvailable
          autoPayMonthlyPricePeriod
          autoPayPriceMethod
          bypassPayment
          callActionReason
          callStatusCode
          companyBrandingId
          companyDataServicesId
          companyName
          createdAt
          crmCompanyId
          crmDayCode
          crmHandheld
          crmMeasurementUnit
          crmNewRecordStatusScode
          crmPortalUrl
          crmQuoteStatusCode
          crmRoute
          crmSaleStatusCode
          crmSolution
          crmLeadSolution
          currency
          customerAddress
          customerCity
          customerEmail
          customerName
          customerPhone
          customerState
          customerZip
          dashboardEmailAddress
          dashboardGreetingName
          dashboardUsername
          emergencyTellMeMore
          gettingStartedText
          id
          lawnbotEmployee
          marketingSource
          maxQuotableSize
          minQuotableSize
          noServiceAgreementContent
          onboardingJson
          paymentType
          pestPacTenantId
          prePayAvailable
          serviceAgreementContent
          serviceAgreementTellMeMore
          serviceTax
          taxOverride
          termsOfService
          updatedAt
          version
          __typename
        }
        createdAt
        customSchedulingVerbiage
        dontTaxZipCodes
        forceKey
        greeting
        hideArielAndStreetView
        hideResidentialOrCommercialQuestion
        hideSummary
        id
        isLive
        isMetric
        learnMore
        lookupZipCodeTax
        measurementMode
        objections
        preModal
        redirectUrl
        salutation
        sections {
          nextToken
          __typename
        }
        serviceQuestions
        skipProbingQuestions
        sqFtPercentage
        sqftEstimateSource
        updatedAt
        version
        willRedirect
        zillowFallback
        zipCodes
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listBots = /* GraphQL */ `
  query ListBots(
    $filter: ModelBotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        botName
        botQuoteAnalyzingGif
        botSpeed
        botStatus
        botTitle
        botType
        botVersion
        company {
          autoPayAlternativeLabel
          autoPayAvailable
          autoPayMonthlyPricePeriod
          autoPayPriceMethod
          bypassPayment
          callActionReason
          callStatusCode
          companyName
          createdAt
          crmCompanyId
          crmDayCode
          crmHandheld
          crmNewRecordStatusScode
          crmPortalUrl
          crmQuoteStatusCode
          crmRoute
          crmSaleStatusCode
          crmSolution
          crmLeadSolution
          customerAddress
          customerCity
          customerEmail
          customerName
          customerPhone
          customerState
          customerZip
          dashboardEmailAddress
          dashboardGreetingName
          dashboardUsername
          id
          lawnbotEmployee
          marketingSource
          maxQuotableSize
          minQuotableSize
          paymentType
          prePayAvailable
          serviceTax
          termsOfService
          updatedAt
          __typename
        }
        createdAt
        dontTaxZipCodes
        greeting
        hideSummary
        id
        isLive
        isMetric
        learnMore
        lookupZipCodeTax
        measurementMode
        objections
        preModal
        redirectUrl
        salutation
        serviceQuestions
        skipProbingQuestions
        sqFtPercentage
        sqftEstimateSource
        updatedAt
        willRedirect
        zillowFallback
        zipCodes
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCompanyBrandingVersioneds = /* GraphQL */ `
  query ListCompanyBrandingVersioneds(
    $filter: ModelCompanyBrandingVersionedFilterInput
    $id: ID
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $version: ModelStringKeyConditionInput
  ) {
    listCompanyBrandingVersioneds(
      filter: $filter
      id: $id
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      version: $version
    ) {
      items {
        aboutUsAssetType
        aboutUsAssetUrl
        company {
          approved
          approvedBy
          approvedOn
          autoPayAlternativeLabel
          autoPayAvailable
          autoPayMonthlyPricePeriod
          autoPayPriceMethod
          bypassPayment
          callActionReason
          callStatusCode
          companyBrandingId
          companyDataServicesId
          companyName
          createdAt
          crmCompanyId
          crmDayCode
          crmHandheld
          crmMeasurementUnit
          crmNewRecordStatusScode
          crmPortalUrl
          crmQuoteStatusCode
          crmRoute
          crmSaleStatusCode
          crmSolution
          crmLeadSolution
          currency
          customerAddress
          customerCity
          customerEmail
          customerName
          customerPhone
          customerState
          customerZip
          dashboardEmailAddress
          dashboardGreetingName
          dashboardUsername
          emergencyTellMeMore
          gettingStartedText
          id
          lawnbotEmployee
          marketingSource
          maxQuotableSize
          minQuotableSize
          noServiceAgreementContent
          onboardingJson
          paymentType
          pestPacTenantId
          prePayAvailable
          serviceAgreementContent
          serviceAgreementTellMeMore
          serviceTax
          taxOverride
          termsOfService
          updatedAt
          version
          __typename
        }
        companyAvatar
        companyBrandingCompanyId
        companyLogo
        createdAt
        id
        instaQuoteMessage
        quoteModalImage
        tagLine
        updatedAt
        valueAddDescription
        valueAddImage
        version
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCompanyBrandings = /* GraphQL */ `
  query ListCompanyBrandings(
    $filter: ModelCompanyBrandingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyBrandings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        aboutUsAssetType
        aboutUsAssetUrl
        company {
          autoPayAlternativeLabel
          autoPayAvailable
          autoPayMonthlyPricePeriod
          autoPayPriceMethod
          bypassPayment
          callActionReason
          callStatusCode
          companyName
          createdAt
          crmCompanyId
          crmDayCode
          crmHandheld
          crmNewRecordStatusScode
          crmPortalUrl
          crmQuoteStatusCode
          crmRoute
          crmSaleStatusCode
          crmSolution
          crmLeadSolution
          customerAddress
          customerCity
          customerEmail
          customerName
          customerPhone
          customerState
          customerZip
          dashboardEmailAddress
          dashboardGreetingName
          dashboardUsername
          id
          lawnbotEmployee
          marketingSource
          maxQuotableSize
          minQuotableSize
          paymentType
          prePayAvailable
          serviceTax
          termsOfService
          updatedAt
          __typename
        }
        companyAvatar
        companyLogo
        createdAt
        id
        instaQuoteMessage
        quoteModalImage
        tagLine
        updatedAt
        valueAddDescription
        valueAddImage
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCompanyDataServicess = /* GraphQL */ `
  query ListCompanyDataServicess(
    $filter: ModelCompanyDataServicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyDataServicess(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        airTableBase
        airTableTableName
        company {
          approved
          approvedBy
          approvedOn
          autoPayAlternativeLabel
          autoPayAvailable
          autoPayMonthlyPricePeriod
          autoPayPriceMethod
          bypassPayment
          callActionReason
          callStatusCode
          companyBrandingId
          companyDataServicesId
          companyName
          createdAt
          crmCompanyId
          crmDayCode
          crmHandheld
          crmMeasurementUnit
          crmNewRecordStatusScode
          crmPortalUrl
          crmQuoteStatusCode
          crmRoute
          crmSaleStatusCode
          crmSolution
          crmLeadSolution
          currency
          customerAddress
          customerCity
          customerEmail
          customerName
          customerPhone
          customerState
          customerZip
          dashboardEmailAddress
          dashboardGreetingName
          dashboardUsername
          emergencyTellMeMore
          gettingStartedText
          id
          lawnbotEmployee
          marketingSource
          maxQuotableSize
          minQuotableSize
          noServiceAgreementContent
          onboardingJson
          paymentType
          pestPacTenantId
          prePayAvailable
          serviceAgreementContent
          serviceAgreementTellMeMore
          serviceTax
          taxOverride
          termsOfService
          updatedAt
          version
          __typename
        }
        companyDataServicesCompanyId
        createdAt
        googleSheetsClientEmail
        googleSheetsPrivateKey
        id
        realGreenAPIKey
        salesCenterConfig
        serviceAssistantId
        servmanApiAuth
        servmanApiKey
        servmanApiUrl
        stripeSecret
        stripeTestSecret
        updatedAt
        zillowAPIKey
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCompanyVersioneds = /* GraphQL */ `
  query ListCompanyVersioneds(
    $filter: ModelCompanyVersionedFilterInput
    $id: ID
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $version: ModelStringKeyConditionInput
  ) {
    listCompanyVersioneds(
      filter: $filter
      id: $id
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      version: $version
    ) {
      items {
        approved
        approvedBy
        approvedOn
        autoPayAlternativeLabel
        autoPayAvailable
        autoPayMonthlyPricePeriod
        autoPayPriceMethod
        bots {
          nextToken
          __typename
        }
        branding {
          aboutUsAssetType
          aboutUsAssetUrl
          companyAvatar
          companyBrandingCompanyId
          companyLogo
          createdAt
          id
          instaQuoteMessage
          quoteModalImage
          tagLine
          updatedAt
          valueAddDescription
          valueAddImage
          version
          __typename
        }
        bypassPayment
        callActionReason
        callStatusCode
        companyBrandingId
        companyDataServicesId
        companyName
        createdAt
        crmCompanyId
        crmDayCode
        crmHandheld
        crmMeasurementUnit
        crmNewRecordStatusScode
        crmPortalUrl
        crmQuoteStatusCode
        crmRoute
        crmSaleStatusCode
        crmSolution
        crmLeadSolution
        currency
        customerAddress
        customerCity
        customerEmail
        customerName
        customerPhone
        customerState
        customerZip
        dashboardEmailAddress
        dashboardGreetingName
        dashboardUsername
        dataServices {
          airTableBase
          airTableTableName
          companyDataServicesCompanyId
          createdAt
          googleSheetsClientEmail
          googleSheetsPrivateKey
          id
          realGreenAPIKey
          salesCenterConfig
          serviceAssistantId
          servmanApiAuth
          servmanApiKey
          servmanApiUrl
          stripeSecret
          stripeTestSecret
          updatedAt
          zillowAPIKey
          __typename
        }
        emergencyTellMeMore
        gettingStartedText
        id
        lawnbotEmployee
        marketingSource
        maxQuotableSize
        minQuotableSize
        noServiceAgreementContent
        offers {
          nextToken
          __typename
        }
        onboardingJson
        paymentType
        pestPacTenantId
        prePayAvailable
        priceTables {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        serviceAgreementContent
        serviceAgreementTellMeMore
        serviceTax
        taxOverride
        termsOfService
        updatedAt
        version
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        autoPayAlternativeLabel
        autoPayAvailable
        autoPayMonthlyPricePeriod
        autoPayPriceMethod
        bots {
          nextToken
          __typename
        }
        branding {
          aboutUsAssetType
          aboutUsAssetUrl
          companyAvatar
          companyLogo
          createdAt
          id
          instaQuoteMessage
          quoteModalImage
          tagLine
          updatedAt
          valueAddDescription
          valueAddImage
          __typename
        }
        bypassPayment
        callActionReason
        callStatusCode
        companyName
        createdAt
        crmCompanyId
        crmDayCode
        crmHandheld
        crmNewRecordStatusScode
        crmPortalUrl
        crmQuoteStatusCode
        crmRoute
        crmSaleStatusCode
        crmSolution
        crmLeadSolution
        customerAddress
        customerCity
        customerEmail
        customerName
        customerPhone
        customerState
        customerZip
        dashboardEmailAddress
        dashboardGreetingName
        dashboardUsername
        id
        lawnbotEmployee
        marketingSource
        maxQuotableSize
        minQuotableSize
        paymentType
        prePayAvailable
        priceTables {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        serviceTax
        termsOfService
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listComponents = /* GraphQL */ `
  query ListComponents(
    $filter: ModelComponentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComponents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        componentName
        createdAt
        id
        props {
          nextToken
          __typename
        }
        step {
          asMessage
          createdAt
          end
          id
          message
          optionType
          stepName
          trigger
          updatedAt
          waitAction
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listConditionals = /* GraphQL */ `
  query ListConditionals(
    $filter: ModelConditionalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConditionals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        id
        matches
        section {
          createdAt
          entryId
          id
          nextSectionId
          sectionName
          updatedAt
          __typename
        }
        trigger
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listOffers = /* GraphQL */ `
  query ListOffers(
    $filter: ModelOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOffers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        active
        company {
          approved
          approvedBy
          approvedOn
          autoPayAlternativeLabel
          autoPayAvailable
          autoPayMonthlyPricePeriod
          autoPayPriceMethod
          bypassPayment
          callActionReason
          callStatusCode
          companyBrandingId
          companyDataServicesId
          companyName
          createdAt
          crmCompanyId
          crmDayCode
          crmHandheld
          crmMeasurementUnit
          crmNewRecordStatusScode
          crmPortalUrl
          crmQuoteStatusCode
          crmRoute
          crmSaleStatusCode
          crmSolution
          crmLeadSolution
          currency
          customerAddress
          customerCity
          customerEmail
          customerName
          customerPhone
          customerState
          customerZip
          dashboardEmailAddress
          dashboardGreetingName
          dashboardUsername
          emergencyTellMeMore
          gettingStartedText
          id
          lawnbotEmployee
          marketingSource
          maxQuotableSize
          minQuotableSize
          noServiceAgreementContent
          onboardingJson
          paymentType
          pestPacTenantId
          prePayAvailable
          serviceAgreementContent
          serviceAgreementTellMeMore
          serviceTax
          taxOverride
          termsOfService
          updatedAt
          version
          __typename
        }
        createdAt
        endDate
        hide
        id
        name
        offerCompanyId
        programs
        startDate
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listOptions = /* GraphQL */ `
  query ListOptions(
    $filter: ModelOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        color
        createdAt
        iconClass
        id
        image
        label
        step {
          asMessage
          createdAt
          end
          id
          message
          optionType
          stepName
          trigger
          updatedAt
          waitAction
          __typename
        }
        updatedAt
        value
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listPriceTableRowss = /* GraphQL */ `
  query ListPriceTableRowss(
    $filter: ModelPriceTableRowsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPriceTableRowss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        end
        id
        price
        priceTable {
          conditions
          createdAt
          id
          isDeleted
          name
          prePayCutoff
          prePayDiscount
          priceTableCompanyId
          serviceType
          updatedAt
          version
          year
          __typename
        }
        priceTableRowsPriceTableId
        start
        units
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listPriceTableVersioneds = /* GraphQL */ `
  query ListPriceTableVersioneds(
    $filter: ModelPriceTableVersionedFilterInput
    $id: ID
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $version: ModelStringKeyConditionInput
  ) {
    listPriceTableVersioneds(
      filter: $filter
      id: $id
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      version: $version
    ) {
      items {
        company {
          autoPayAlternativeLabel
          autoPayAvailable
          autoPayMonthlyPricePeriod
          autoPayPriceMethod
          bypassPayment
          callActionReason
          callStatusCode
          companyName
          createdAt
          crmCompanyId
          crmDayCode
          crmHandheld
          crmNewRecordStatusScode
          crmPortalUrl
          crmQuoteStatusCode
          crmRoute
          crmSaleStatusCode
          crmSolution
          crmLeadSolution
          customerAddress
          customerCity
          customerEmail
          customerName
          customerPhone
          customerState
          customerZip
          dashboardEmailAddress
          dashboardGreetingName
          dashboardUsername
          id
          lawnbotEmployee
          marketingSource
          maxQuotableSize
          minQuotableSize
          paymentType
          prePayAvailable
          serviceTax
          termsOfService
          updatedAt
          __typename
        }
        conditions
        createdAt
        id
        isDeleted
        name
        prePayCutoff
        prePayDiscount
        priceTableCompanyId
        rows {
          nextToken
          __typename
        }
        serviceType
        updatedAt
        version
        year
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listPriceTables = /* GraphQL */ `
  query ListPriceTables(
    $filter: ModelPriceTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPriceTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        company {
          autoPayAlternativeLabel
          autoPayAvailable
          autoPayMonthlyPricePeriod
          autoPayPriceMethod
          bypassPayment
          callActionReason
          callStatusCode
          companyName
          createdAt
          crmCompanyId
          crmDayCode
          crmHandheld
          crmNewRecordStatusScode
          crmPortalUrl
          crmQuoteStatusCode
          crmRoute
          crmSaleStatusCode
          crmSolution
          crmLeadSolution
          customerAddress
          customerCity
          customerEmail
          customerName
          customerPhone
          customerState
          customerZip
          dashboardEmailAddress
          dashboardGreetingName
          dashboardUsername
          id
          lawnbotEmployee
          marketingSource
          maxQuotableSize
          minQuotableSize
          paymentType
          prePayAvailable
          serviceTax
          termsOfService
          updatedAt
          __typename
        }
        conditions
        createdAt
        id
        name
        prePayCutoff
        prePayDiscount
        serviceType
        updatedAt
        year
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listProbingQuestionss = /* GraphQL */ `
  query ListProbingQuestionss(
    $filter: ModelProbingQuestionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProbingQuestionss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        conditionals {
          createdAt
          id
          matches
          trigger
          updatedAt
          __typename
        }
        createdAt
        entryId
        id
        nextSectionId
        probingQuestionsProgramId
        program {
          active
          alternativeApplicationString
          alternativePaymentVerbiage
          alwaysAvailable
          alwaysOnTop
          conditions
          createdAt
          crmProgamCode
          excludedZips
          googleSheetRangeMapping
          googleSheetResultsMapping
          googleSheetSheetId
          googleSheetTabName
          hideDiscountText
          id
          isDeleted
          isUpsell
          probingQuestionsJson
          productOffering
          programCompanyId
          programDescription
          programEndMonth
          programName
          programProbingQuestionsId
          programStartMonth
          programType
          realGreenApplicationDiscountCode
          realGreenPrepayDiscountCode
          recurringPricingTable
          seasonalText
          updatedAt
          upfrontPricingTable
          useProbingQuestions
          useRealGreenPricingEngine
          usesGoogleSheetPricing
          usesUpfrontPricing
          version
          __typename
        }
        sectionName
        steps {
          asMessage
          createdAt
          end
          id
          message
          optionType
          stepName
          trigger
          updatedAt
          waitAction
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listProgramVersioneds = /* GraphQL */ `
  query ListProgramVersioneds(
    $filter: ModelProgramVersionedFilterInput
    $id: ID
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $version: ModelStringKeyConditionInput
  ) {
    listProgramVersioneds(
      filter: $filter
      id: $id
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      version: $version
    ) {
      items {
        active
        alternativeApplicationString
        alternativePaymentVerbiage
        alwaysAvailable
        alwaysOnTop
        company {
          approved
          approvedBy
          approvedOn
          autoPayAlternativeLabel
          autoPayAvailable
          autoPayMonthlyPricePeriod
          autoPayPriceMethod
          bypassPayment
          callActionReason
          callStatusCode
          companyBrandingId
          companyDataServicesId
          companyName
          createdAt
          crmCompanyId
          crmDayCode
          crmHandheld
          crmMeasurementUnit
          crmNewRecordStatusScode
          crmPortalUrl
          crmQuoteStatusCode
          crmRoute
          crmSaleStatusCode
          crmSolution
          crmLeadSolution
          currency
          customerAddress
          customerCity
          customerEmail
          customerName
          customerPhone
          customerState
          customerZip
          dashboardEmailAddress
          dashboardGreetingName
          dashboardUsername
          emergencyTellMeMore
          gettingStartedText
          id
          lawnbotEmployee
          marketingSource
          maxQuotableSize
          minQuotableSize
          noServiceAgreementContent
          onboardingJson
          paymentType
          pestPacTenantId
          prePayAvailable
          serviceAgreementContent
          serviceAgreementTellMeMore
          serviceTax
          taxOverride
          termsOfService
          updatedAt
          version
          __typename
        }
        conditions
        createdAt
        crmProgamCode
        excludedZips
        googleSheetRangeMapping
        googleSheetResultsMapping
        googleSheetSheetId
        googleSheetTabName
        hideDiscountText
        id
        isDeleted
        isUpsell
        probingQuestions {
          createdAt
          entryId
          id
          nextSectionId
          probingQuestionsProgramId
          sectionName
          updatedAt
          __typename
        }
        probingQuestionsJson
        productOffering
        programCompanyId
        programDescription
        programEndMonth
        programName
        programProbingQuestionsId
        programStartMonth
        programType
        realGreenApplicationDiscountCode
        realGreenPrepayDiscountCode
        recurringPricingTable
        seasonalText
        services {
          nextToken
          __typename
        }
        updatedAt
        upfrontPricingTable
        useProbingQuestions
        useRealGreenPricingEngine
        usesGoogleSheetPricing
        usesUpfrontPricing
        version
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listPrograms = /* GraphQL */ `
  query ListPrograms(
    $filter: ModelProgramFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrograms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        active
        alternativeApplicationString
        alternativePaymentVerbiage
        alwaysAvailable
        alwaysOnTop
        company {
          autoPayAlternativeLabel
          autoPayAvailable
          autoPayMonthlyPricePeriod
          autoPayPriceMethod
          bypassPayment
          callActionReason
          callStatusCode
          companyName
          createdAt
          crmCompanyId
          crmDayCode
          crmHandheld
          crmNewRecordStatusScode
          crmPortalUrl
          crmQuoteStatusCode
          crmRoute
          crmSaleStatusCode
          crmSolution
          crmLeadSolution
          customerAddress
          customerCity
          customerEmail
          customerName
          customerPhone
          customerState
          customerZip
          dashboardEmailAddress
          dashboardGreetingName
          dashboardUsername
          id
          lawnbotEmployee
          marketingSource
          maxQuotableSize
          minQuotableSize
          paymentType
          prePayAvailable
          serviceTax
          termsOfService
          updatedAt
          __typename
        }
        conditions
        createdAt
        crmProgamCode
        excludedZips
        googleSheetRangeMapping
        googleSheetResultsMapping
        googleSheetSheetId
        googleSheetTabName
        id
        isUpsell
        probingQuestionsJson
        productOffering
        programDescription
        programEndMonth
        programName
        programStartMonth
        programType
        realGreenApplicationDiscountCode
        realGreenPrepayDiscountCode
        seasonalText
        services {
          nextToken
          __typename
        }
        updatedAt
        upfrontPricingTable
        useProbingQuestions
        useRealGreenPricingEngine
        usesGoogleSheetPricing
        usesUpfrontPricing
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listProps = /* GraphQL */ `
  query ListProps(
    $filter: ModelPropFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        component {
          componentName
          createdAt
          id
          updatedAt
          __typename
        }
        createdAt
        id
        key
        updatedAt
        value
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listSections = /* GraphQL */ `
  query ListSections(
    $filter: ModelSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        bot {
          botCompanyId
          botName
          botQuoteAnalyzingGif
          botSpeed
          botStatus
          botTitle
          botType
          botVersion
          createdAt
          customSchedulingVerbiage
          dontTaxZipCodes
          forceKey
          greeting
          hideArielAndStreetView
          hideResidentialOrCommercialQuestion
          hideSummary
          id
          isLive
          isMetric
          learnMore
          lookupZipCodeTax
          measurementMode
          objections
          preModal
          redirectUrl
          salutation
          serviceQuestions
          skipProbingQuestions
          sqFtPercentage
          sqftEstimateSource
          updatedAt
          version
          willRedirect
          zillowFallback
          zipCodes
          __typename
        }
        conditionals {
          nextToken
          __typename
        }
        createdAt
        entryId
        id
        nextSectionId
        sectionName
        steps {
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listServiceTypes = /* GraphQL */ `
  query ListServiceTypes(
    $filter: ModelServiceTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        id
        name
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listServiceVersioneds = /* GraphQL */ `
  query ListServiceVersioneds(
    $filter: ModelServiceVersionedFilterInput
    $id: ID
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $version: ModelStringKeyConditionInput
  ) {
    listServiceVersioneds(
      filter: $filter
      id: $id
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      version: $version
    ) {
      items {
        createdAt
        cutOffDate
        id
        isDeleted
        priceTableId
        program {
          active
          alternativeApplicationString
          alternativePaymentVerbiage
          alwaysAvailable
          alwaysOnTop
          conditions
          createdAt
          crmProgamCode
          excludedZips
          googleSheetRangeMapping
          googleSheetResultsMapping
          googleSheetSheetId
          googleSheetTabName
          hideDiscountText
          id
          isDeleted
          isUpsell
          probingQuestionsJson
          productOffering
          programCompanyId
          programDescription
          programEndMonth
          programName
          programProbingQuestionsId
          programStartMonth
          programType
          realGreenApplicationDiscountCode
          realGreenPrepayDiscountCode
          recurringPricingTable
          seasonalText
          updatedAt
          upfrontPricingTable
          useProbingQuestions
          useRealGreenPricingEngine
          usesGoogleSheetPricing
          usesUpfrontPricing
          version
          __typename
        }
        roundNumber
        serviceName
        serviceProgramId
        updatedAt
        version
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        cutOffDate
        id
        priceTableId
        program {
          active
          alternativeApplicationString
          alternativePaymentVerbiage
          alwaysAvailable
          alwaysOnTop
          conditions
          createdAt
          crmProgamCode
          excludedZips
          googleSheetRangeMapping
          googleSheetResultsMapping
          googleSheetSheetId
          googleSheetTabName
          id
          isUpsell
          probingQuestionsJson
          productOffering
          programDescription
          programEndMonth
          programName
          programStartMonth
          programType
          realGreenApplicationDiscountCode
          realGreenPrepayDiscountCode
          seasonalText
          updatedAt
          upfrontPricingTable
          useProbingQuestions
          useRealGreenPricingEngine
          usesGoogleSheetPricing
          usesUpfrontPricing
          __typename
        }
        roundNumber
        serviceName
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listSteps = /* GraphQL */ `
  query ListSteps(
    $filter: ModelStepFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSteps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        asMessage
        component {
          componentName
          createdAt
          id
          updatedAt
          __typename
        }
        createdAt
        end
        id
        message
        optionType
        options {
          nextToken
          __typename
        }
        section {
          createdAt
          entryId
          id
          nextSectionId
          sectionName
          updatedAt
          __typename
        }
        stepName
        trigger
        updatedAt
        waitAction
        __typename
      }
      nextToken
      __typename
    }
  }
`;
