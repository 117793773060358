export const templatedPets = [
  {
    name: "Pet Waste Probing Questions",
    type: "pet-waste",
    steps: [
      {
        id: "pet-waste-entry",
        message: `How many pets do you have that are producing the waste?`,
        trigger: "get-how-many-pets"
      }
    ],
    probing_questions: [
      {
        id: "get-how-many-pets",
        component: {
          type: "userinput",
          placeholder: "Number of Pets (i.e. 3 )"
        },
        waitAction: true,
        setVariable: "numberOfPets",
        trigger: "next-service"
      }
    ]
  }
];
