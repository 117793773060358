import React, { useEffect, useState, useCallback } from "react";
import {
  createNewVersion,
  createInitialVersions,
  createInitialId
} from "../../utils/versionUtilFuncs";
import { useParams } from "react-router-dom";
import {
  Container,
  makeStyles,
  createStyles,
  InputLabel,
  TextField,
  Button,
  Snackbar,
  Breadcrumbs,
  Link,
  Typography,
  Modal,
  CircularProgress
} from "@material-ui/core";

import Title from "../dashboard/Title";
import S3ImageUploader from "../../components/uploader/s3-image-uploader";
import { checkCompanyAfterFetch } from "./companyUtils";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap"
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(1),
      width: "100%"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    },
    editorStyle: {
      border: "1px solid #ccc",
      borderRadius: 10,
      padding: 20
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "2px solid #000",
      boxShadow: theme.shadows[5]
    },
    insideModal: {
      alignItems: "center",
      backgroundColor: "#FAFAFA",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: theme.spacing(4, 4, 2, 4)
    }
  })
);

export function CompanyBranding() {
  let { companyId } = useParams();
  const classes = useStyles();
  const [company, setCompany] = useState({});
  const [branding, setBranding] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [snackOpened, openSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("TagLine Saved");

  const fetchCompany = useCallback(async () => {
    try {
      const fetch = await checkCompanyAfterFetch(companyId);
      setCompany(fetch.data.getCompany);
      setBranding(
        fetch.data.getCompany.branding || {
          companyBrandingCompanyId: fetch.data.getCompany.id,
          instaQuoteMessage: `Welcome to instaquote`,
          tagLine: "",
          companyLogo: null,
          companyAvatar: null
        }
      );
      setLoaded(true);
    } catch (error) {
      console.log("error: ", error);
    }
  }, [companyId]);

  useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);

  const updateBrandingCb = async (
    type,
    url,
    contentType = "image/png",
    isRemove = false
  ) => {
    const payload = { ...branding, aboutUsAssetType: contentType };
    isRemove ? (payload[type] = null) : (payload[type] = url);
    isRemove
      ? setSnackMessage(`Removed ${type}`)
      : setSnackMessage(`Saved ${type}`);
    try {
      if (payload.id) {
        delete payload.company;
        const result = await createNewVersion(
          payload.id,
          "companyBranding",
          payload
        );
        setBranding(payload);
        openSnack(true);
      } else {
        payload["companyBrandingCompanyId"] = companyId;
        payload.id = createInitialId(companyId);
        const br = await createInitialVersions("companyBranding", payload);
        const bid = br.id;
        delete br.company;
        setBranding(br);
        const cpayload = { id: company.id, companyBrandingId: bid };
        createNewVersion(companyId, "company", cpayload);
        openSnack(true);
      }
      setUploading(false);
    } catch (error) {
      console.log("error: ", error.errors[0].message);
    }
  };

  const saveTagLine = async (type) => {
    setSnackMessage("Saved");
    const payload = { ...branding };
    try {
      if (payload.id) {
        const result = await createNewVersion(
          payload.id,
          "companyBranding",
          payload
        );
        openSnack(true);
      } else {
        payload["companyBrandingCompanyId"] = companyId;
        payload.id = createInitialId(companyId);
        const br = await createInitialVersions("companyBranding", payload);
        const bid = br.id;
        delete br.company;
        setBranding(br);
        const cpayload = { id: company.id, companyBrandingId: bid };
        createNewVersion(companyId, "company", cpayload);
        // cpayload["companyCompanyBrandingId"] = bid;

        openSnack(true);
      }
    } catch (err) {
      console.log("error: ", err);
    }
  };

  const startLoading = () => {
    setUploading(true);
  };

  const modalBody = (
    <div className={classes.modal}>
      <div className={classes.insideModal}>
        <CircularProgress />
        <h3>Your image is loading...</h3>
      </div>
    </div>
  );

  return (
    <Container className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
        <Link color="inherit" href={`/company/${companyId}`}>
          Company
        </Link>
        <Typography color="textPrimary">Branding</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <Title>Branding</Title>
      </div>
      {loaded ? (
        <div>
          <div className={classes.container}>
            <TextField
              id="tagline"
              label="Tag Line"
              value={branding.tagLine}
              style={{ margin: 8, width: 200 }}
              placeholder={"The Best Lawncare In The Area"}
              margin="normal"
              onChange={(e) => {
                setBranding({ ...branding, tagLine: e.target.value });
              }}
              onBlur={(e) => {
                setBranding({ ...branding, tagLine: e.target.value });
              }}
              errorMessages={["this field is required"]}
              InputLabelProps={{
                shrink: true
              }}
              validators={["required"]}
            />
            <Button color="primary" variant="contained" onClick={saveTagLine}>
              Save TagLine
            </Button>
          </div>
          <div className={classes.container}>
            <TextField
              id="valueadddescription"
              label="Value Add Description"
              value={branding.valueAddDescription}
              style={{ margin: 8, width: 200 }}
              placeholder={"We have been in business 20 years..."}
              multiline={true}
              margin="normal"
              onChange={(e) => {
                setBranding({
                  ...branding,
                  valueAddDescription: e.target.value
                });
              }}
              onBlur={(e) => {
                setBranding({
                  ...branding,
                  valueAddDescription: e.target.value
                });
              }}
              errorMessages={["this field is required"]}
              InputLabelProps={{
                shrink: true
              }}
              validators={["required"]}
            />
            <Button color="primary" variant="contained" onClick={saveTagLine}>
              Save Value Add Description
            </Button>
          </div>
          <Modal
            disableBackdropClick
            open={uploading}
            className={classes.modal}
          >
            {modalBody}
          </Modal>
          <div className={classes.container}>
            <h2>Company Logo</h2>
            <S3ImageUploader
              startLoading={startLoading}
              image={branding.companyLogo}
              filename={"logo.png"}
              type={"companyLogo"}
              uploaded={updateBrandingCb}
              allowableContentType="image/png"
            />
          </div>
          <div className={classes.container}>
            <h2>Company Avatar</h2>
            <S3ImageUploader
              startLoading={startLoading}
              image={branding.companyAvatar}
              filename={"avatar"}
              type={"companyAvatar"}
              uploaded={updateBrandingCb}
              allowableContentType="image/png"
            />
          </div>
          <div className={classes.container}>
            <h2>Value Add Image ( Team Image )</h2>
            <S3ImageUploader
              startLoading={startLoading}
              image={branding.valueAddImage}
              filename={"valueadd"}
              type={"valueAddImage"}
              uploaded={updateBrandingCb}
              allowableContentType="image/png"
            />
          </div>
          <div className={classes.container}>
            <h2>Quote Modal Image</h2>
            <S3ImageUploader
              startLoading={startLoading}
              image={branding.quoteModalImage}
              filename={"quotemodal"}
              type={"quoteModalImage"}
              uploaded={updateBrandingCb}
              allowableContentType="image/png"
            />
          </div>
          <div className={classes.container}>
            <h2>Learn More Image or Video</h2>
            <S3ImageUploader
              startLoading={startLoading}
              image={branding.aboutUsAssetUrl}
              filename={"aboutUsasset"}
              type={"aboutUsAssetUrl"}
              uploaded={updateBrandingCb}
              allowableContentType="video/mp4,image/png"
            />
          </div>
          <Snackbar
            open={snackOpened}
            autoHideDuration={2000}
            onClose={() => {
              openSnack(false);
            }}
            message={snackMessage}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
          ></Snackbar>
        </div>
      ) : null}
    </Container>
  );
}
