export const serviceQuestionTemplate = () => {
  const q = [
    {
      name: "",
      type: "mowing",
      steps: [
        {
          id: "service-questions-entry",
          message: `Do you have a locked gate?`,
          trigger: "get-locked-gate"
        },
        {
          id: "get-notification-before",
          message: `Would you like to be emailed or texted prior to each visit?`,
          trigger: "ask-notification-before"
        },
        {
          id: "ask-invisible-fence-or-irrigation",
          message: "Do you have an invisible fence or irrigation system",
          trigger: "get-invisible-fence-or-irrigation"
        },
        {
          id: "ask-treenshrub-question",
          message:
            "Would you like to be contacted about our Tree and Shrub care services?",
          trigger: "get-treenshrub-answer"
        },
        {
          id: "service-well-contact-you",
          message: `Great! Our office will contact you soon.`,
          trigger: "well-thank-you"
        },
        {
          id: "well-thank-you",
          message: `Well thank you for your purchase...`,
          end: true
        }
      ],
      probing_questions: [
        {
          id: "get-locked-gate",
          options: [
            { value: "Yes", label: "Yes", hideText: true },
            { value: "No", label: "No", hideText: true }
          ],
          optionType: "default",
          trigger: "get-notification-before",
          setVariable: "lockedGate"
        },
        {
          id: "get-invisible-fence-or-irrigation",
          options: [
            {
              value: "Yes",
              label: "Yes",
              hideText: true
            },
            {
              value: "No",
              label: "No",
              hideText: true
            }
          ],
          optionType: "default",
          trigger: "service-well-contact-you",
          setVariable: "invisibleFenceOrIrrigation"
        },
        {
          id: "get-treenshrub-answer",
          options: [
            {
              value: "Yes",
              label: "Yes",
              hideText: true
            },
            {
              value: "No",
              label: "No",
              hideText: true
            }
          ],
          conditionals: [
            {
              matches: "Yes",
              trigger: "service-well-contact-you"
            },
            {
              matches: "No",
              trigger: "well-thank-you"
            }
          ],
          optionType: "default",
          trigger: "well-thank-you",
          setVariable: "irrigationSystem"
        },

        {
          id: "ask-notification-before",
          options: [
            { value: "Email", label: "Email", hideText: true },
            { value: "Text Message", label: "Text Message", hideText: true },
            { value: "Both", label: "Both", hideText: true },
            {
              value: "Don't Notify Me",
              label: "Don't Notify Me",
              hideText: true
            }
          ],
          optionType: "default",
          setVariable: "notificationMethod",
          trigger: "service-well-contact-you"
        }
      ]
    }
  ];

  return JSON.stringify(q, null, 2);
};
