import { Button, createStyles, makeStyles, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";

import * as PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap"
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    }
  })
);

export const DndMessageForm = (props) => {
  const [step, setStep] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    setStep(props.step);
  }, [props.step]);

  const updateMessage = (data) => {
    props.callback(step);
  };

  return step ? (
    <div className={classes.container}>
      <ValidatorForm onSubmit={updateMessage}>
        <TextField
          value={step.id}
          onChange={(e) => setStep({ ...step, id: e.target.value })}
          placeholder={"Step Id"}
        />
        <br />
        <TextField
          value={step.message}
          onChange={(e) => setStep({ ...step, message: e.target.value })}
          placeholder={"Message"}
        />
        <br />
        <TextField
          value={step.trigger}
          onChange={(e) => setStep({ ...step, trigger: e.target.value })}
          placeholder={"Trigger"}
        />
        <br />
        <Button type={"submit"}>Submit</Button>
      </ValidatorForm>
    </div>
  ) : null;
};

DndMessageForm.propTypes = {
  step: PropTypes.array,
  callback: PropTypes.func
};
