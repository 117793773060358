import React, { useCallback, useEffect, useState } from "react";
import { FormLabel, MenuItem, Select, TextField } from "@material-ui/core";

export function SalesCenterDataServicesFormFields(props) {
  const baseUrlOptions = [
    { label: "", value: "" },
    {
      label: "Dev (https://dev-api.marketing.workwave.com)",
      value: "https://dev-api.marketing.workwave.com"
    },
    {
      label: "Staging (https://staging-api.marketing.workwave.com)",
      value: "https://staging-api.marketing.workwave.com"
    },
    {
      label: "Prod (https://api.marketing.workwave.com)",
      value: "https://api.marketing.workwave.com"
    }
  ];

  const { company, dataServices, setDataServices } = props;
  const [salesCenterConfig, setSalesCenterConfig] = useState({});

  useEffect(() => {
    if (company?.id) {
      setSalesCenterConfig(
        company?.dataServices?.salesCenterConfig
          ? JSON.parse(company?.dataServices?.salesCenterConfig)
          : { apiKey: "", tenantId: "", clientId: "", apiBaseUrl: "" }
      );
    }
  }, [company]);

  const changeSalesCenterConfig = useCallback(
    (input) => {
      const newDataServices = {
        ...dataServices,
        salesCenterConfig: JSON.stringify(input)
      };
      setDataServices(newDataServices);
      setSalesCenterConfig(input);
    },
    [dataServices, setDataServices]
  );

  return (
    <>
      <div>
        <TextField
          id="salesCenterApiKey"
          label="Sales Center API Key"
          value={salesCenterConfig?.apiKey || ""}
          style={{ margin: 8, width: 400 }}
          margin="normal"
          InputLabelProps={{
            shrink: true
          }}
          errorMessages={["This field is required"]}
          validators={[]}
          onChange={(e) => {
            changeSalesCenterConfig({
              ...salesCenterConfig,
              apiKey: e.target.value
            });
          }}
        />
      </div>
      <div>
        <TextField
          id="salesCenterTenantId"
          label="Sales Center Tenant Id"
          value={salesCenterConfig?.tenantId || ""}
          style={{ margin: 8, width: 400 }}
          margin="normal"
          InputLabelProps={{
            shrink: true
          }}
          errorMessages={["This field is required"]}
          validators={[]}
          onChange={(e) => {
            changeSalesCenterConfig({
              ...salesCenterConfig,
              tenantId: e.target.value
            });
          }}
        />
      </div>
      <div>
        <TextField
          id="salesCenterClientId"
          label="Sales Center Client Id"
          value={salesCenterConfig?.clientId || ""}
          style={{ margin: 8, width: 400 }}
          margin="normal"
          InputLabelProps={{
            shrink: true
          }}
          errorMessages={["This field is required"]}
          validators={[]}
          onChange={(e) => {
            changeSalesCenterConfig({
              ...salesCenterConfig,
              clientId: e.target.value
            });
          }}
        />
      </div>
      <div>
        <FormLabel style={{ fontSize: "0.75rem", marginLeft: "8px" }}>
          Sales Center API Base URL
        </FormLabel>
        <br />
        <Select
          id="salesCenterBaseUrl"
          label="Sales Center API Base URL"
          value={salesCenterConfig?.apiBaseUrl || ""}
          style={{ margin: 8, width: 400 }}
          placeholder="Sales Center API Base URL"
          margin="normal"
          onChange={(e) => {
            changeSalesCenterConfig({
              ...salesCenterConfig,
              apiBaseUrl: e.target.value
            });
          }}
        >
          {baseUrlOptions.map((i) => {
            const key = "menu-item-" + i.value;
            return (
              <MenuItem style={{ minHeight: "35px" }} key={key} value={i.value}>
                {`${i.label}`}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    </>
  );
}
